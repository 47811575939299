import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import React from 'react';
import { Control, Controller, Path, PathValue, UnpackNestedValue } from 'react-hook-form';

interface Props<T, TFieldValues> {
  control: Control<TFieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  getOptionLabel: (option: T) => string;
  options: T[];
  multiple: boolean;
  name: Path<TFieldValues>;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  onChange?: (
    event: React.SyntheticEvent,
    newValues: string | T | (string | T)[] | null,
    callback: (newData: string | T | (string | T)[] | null) => void,
  ) => void;
  defaultValue: T | T[];
  getOptionSelected: (option: T, value: T) => boolean;
}

export function isArrayType<T>(value: T | T[]): value is T[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (value as any).map === 'function';
}

export default function ControlledAutoComplete<T, TFieldValues>({
  control,
  style,
  getOptionLabel,
  options,
  name,
  multiple,
  renderInput,
  defaultValue,
  onChange,
  getOptionSelected,
}: Props<T, TFieldValues>): JSX.Element {
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={field.value as T | T[]}
          style={style}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getOptionSelected}
          renderInput={renderInput}
          // eslint-disable-next-line react/prop-types
          onChange={(_, data) => {
            if (onChange) {
              onChange(_, data, (newData) => {
                console.log(`setting value to ${JSON.stringify(data)}`);
                field.onChange(newData);
              });
            } else {
              console.log(`setting value to ${JSON.stringify(data)}`);
              return field.onChange(data);
            }
          }}
          multiple={multiple}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
        />
      )}
      defaultValue={defaultValue as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>}
      name={name}
      control={control}
    />
  );
}
