import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React from 'react';
import BreadCrumbs from './BreadCrumb';

const Frame: React.FunctionComponent = ({ children }) => {
  return (
    <Container
      sx={{
        paddingTop: 2,
        flex: '1 1 100%',
        position: 'relative',
        margin: '0 auto',
        maxWidth: () => '100% !important',
        backgroundColor: (theme) => `${theme.palette.background.default} !important`,
        minHeight: '100vh',
        maxHeight: '100%',
      }}
      component="main"
      id="main-content"
    >
      <BreadCrumbs />
      {children}
    </Container>
  );
};
Frame.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Frame;
