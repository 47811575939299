import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthorizedUrqlProvider from './components/AuthorizedUrqlProvider';
import ENV from './env';
import './index.css';
import { Auth0Provider } from './react-auth0-spa';
import * as serviceWorker from './serviceWorker';

if (ENV.REACT_APP_DISABLE_SENTRY !== '1') {
  Sentry.init({ dsn: ENV.REACT_APP_SENTRY_DSN });
}

// A function that routes the user to the right place
// after login
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = async (appState: any): Promise<void> => {
  console.log(appState);
  window.location = appState && appState.targetUrl ? appState.targetUrl : window.location.pathname;
};

ReactDOM.render(
  <Auth0Provider
    domain={ENV.REACT_APP_AUTH0_DOMAIN || ''}
    client_id={ENV.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={ENV.REACT_APP_AUTH0_AUDIENCE}
  >
    <AuthorizedUrqlProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthorizedUrqlProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
