import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Bytes: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type BytesFilter = {
  equals?: Maybe<Scalars['Bytes']>;
  not?: Maybe<NestedBytesFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type EnumUnitTypeFilter = {
  equals?: Maybe<UnitType>;
  in?: Maybe<Array<UnitType>>;
  not?: Maybe<NestedEnumUnitTypeFilter>;
  notIn?: Maybe<Array<UnitType>>;
};

export type File = {
  __typename?: 'File';
  blob: Scalars['Bytes'];
  id: Scalars['String'];
  mimetype: Scalars['String'];
  owner: User;
  recipe: Recipe;
  step: Array<Step>;
};


export type FileStepArgs = {
  after?: Maybe<StepWhereUniqueInput>;
  before?: Maybe<StepWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FileCreateManyOwnerInput = {
  blob: Scalars['Bytes'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  recipeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileCreateManyOwnerInputEnvelope = {
  data?: Maybe<Array<FileCreateManyOwnerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileCreateManyRecipeInput = {
  blob: Scalars['Bytes'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type FileCreateManyRecipeInputEnvelope = {
  data?: Maybe<Array<FileCreateManyRecipeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileCreateNestedManyWithoutOwnerInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutOwnerInput>>;
  create?: Maybe<Array<FileCreateWithoutOwnerInput>>;
  createMany?: Maybe<FileCreateManyOwnerInputEnvelope>;
};

export type FileCreateNestedManyWithoutRecipeInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutRecipeInput>>;
  create?: Maybe<Array<FileCreateWithoutRecipeInput>>;
  createMany?: Maybe<FileCreateManyRecipeInputEnvelope>;
};

export type FileCreateNestedOneWithoutStepInput = {
  connect?: Maybe<FileWhereUniqueInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutStepInput>;
  create?: Maybe<FileCreateWithoutStepInput>;
};

export type FileCreateOrConnectWithoutOwnerInput = {
  create: FileCreateWithoutOwnerInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutRecipeInput = {
  create: FileCreateWithoutRecipeInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutStepInput = {
  create: FileCreateWithoutStepInput;
  where: FileWhereUniqueInput;
};

export type FileCreateWithoutOwnerInput = {
  blob: Scalars['Bytes'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  recipe: RecipeCreateNestedOneWithoutPhotoInput;
  step?: Maybe<StepCreateNestedManyWithoutPhotoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileCreateWithoutRecipeInput = {
  blob: Scalars['Bytes'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  owner: UserCreateNestedOneWithoutFileInput;
  step?: Maybe<StepCreateNestedManyWithoutPhotoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileCreateWithoutStepInput = {
  blob: Scalars['Bytes'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  owner: UserCreateNestedOneWithoutFileInput;
  recipe: RecipeCreateNestedOneWithoutPhotoInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileListRelationFilter = {
  every?: Maybe<FileWhereInput>;
  none?: Maybe<FileWhereInput>;
  some?: Maybe<FileWhereInput>;
};

export type FileOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type FileOrderByWithRelationInput = {
  blob?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mimetype?: Maybe<SortOrder>;
  owner?: Maybe<UserOrderByWithRelationInput>;
  recipe?: Maybe<RecipeOrderByWithRelationInput>;
  recipeId?: Maybe<SortOrder>;
  step?: Maybe<StepOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type FileScalarWhereInput = {
  AND?: Maybe<Array<FileScalarWhereInput>>;
  NOT?: Maybe<Array<FileScalarWhereInput>>;
  OR?: Maybe<Array<FileScalarWhereInput>>;
  blob?: Maybe<BytesFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringFilter>;
  recipeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type FileUpdateManyMutationInput = {
  blob?: Maybe<Scalars['Bytes']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateManyWithWhereWithoutOwnerInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutRecipeInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithoutOwnerInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutOwnerInput>>;
  create?: Maybe<Array<FileCreateWithoutOwnerInput>>;
  createMany?: Maybe<FileCreateManyOwnerInputEnvelope>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type FileUpdateManyWithoutRecipeInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutRecipeInput>>;
  create?: Maybe<Array<FileCreateWithoutRecipeInput>>;
  createMany?: Maybe<FileCreateManyRecipeInputEnvelope>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutRecipeInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutRecipeInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutRecipeInput>>;
};

export type FileUpdateOneWithoutStepInput = {
  connect?: Maybe<FileWhereUniqueInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutStepInput>;
  create?: Maybe<FileCreateWithoutStepInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FileUpdateWithoutStepInput>;
  upsert?: Maybe<FileUpsertWithoutStepInput>;
};

export type FileUpdateWithWhereUniqueWithoutOwnerInput = {
  data: FileUpdateWithoutOwnerInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutRecipeInput = {
  data: FileUpdateWithoutRecipeInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithoutOwnerInput = {
  blob?: Maybe<Scalars['Bytes']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  recipe?: Maybe<RecipeUpdateOneRequiredWithoutPhotoInput>;
  step?: Maybe<StepUpdateManyWithoutPhotoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateWithoutRecipeInput = {
  blob?: Maybe<Scalars['Bytes']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  owner?: Maybe<UserUpdateOneRequiredWithoutFileInput>;
  step?: Maybe<StepUpdateManyWithoutPhotoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateWithoutStepInput = {
  blob?: Maybe<Scalars['Bytes']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  owner?: Maybe<UserUpdateOneRequiredWithoutFileInput>;
  recipe?: Maybe<RecipeUpdateOneRequiredWithoutPhotoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpsertWithWhereUniqueWithoutOwnerInput = {
  create: FileCreateWithoutOwnerInput;
  update: FileUpdateWithoutOwnerInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutRecipeInput = {
  create: FileCreateWithoutRecipeInput;
  update: FileUpdateWithoutRecipeInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithoutStepInput = {
  create: FileCreateWithoutStepInput;
  update: FileUpdateWithoutStepInput;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  blob?: Maybe<BytesFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringFilter>;
  owner?: Maybe<UserWhereInput>;
  recipe?: Maybe<RecipeWhereInput>;
  recipeId?: Maybe<StringFilter>;
  step?: Maybe<StepListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type Ingredient = {
  __typename?: 'Ingredient';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IngredientCreateInput = {
  IngredientUnit?: Maybe<IngredientUnitCreateNestedManyWithoutIngredientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type IngredientCreateNestedOneWithoutIngredientUnitInput = {
  connect?: Maybe<IngredientWhereUniqueInput>;
  connectOrCreate?: Maybe<IngredientCreateOrConnectWithoutIngredientUnitInput>;
  create?: Maybe<IngredientCreateWithoutIngredientUnitInput>;
};

export type IngredientCreateOrConnectWithoutIngredientUnitInput = {
  create: IngredientCreateWithoutIngredientUnitInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateWithoutIngredientUnitInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type IngredientOrderByWithRelationInput = {
  IngredientUnit?: Maybe<IngredientUnitOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type IngredientUnit = {
  __typename?: 'IngredientUnit';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  ingredient?: Maybe<Ingredient>;
  notes?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  recipeSection: RecipeSection;
  unit?: Maybe<UnitSize>;
};

export type IngredientUnitCreateInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientCreateNestedOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection: RecipeSectionCreateNestedOneWithoutIngredientsInput;
  unit?: Maybe<UnitSizeCreateNestedOneWithoutIngredientUnitInput>;
};

export type IngredientUnitCreateManyIngredientInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSectionId: Scalars['String'];
  unitId?: Maybe<Scalars['String']>;
};

export type IngredientUnitCreateManyIngredientInputEnvelope = {
  data?: Maybe<Array<IngredientUnitCreateManyIngredientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type IngredientUnitCreateManyRecipeSectionInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['String']>;
};

export type IngredientUnitCreateManyRecipeSectionInputEnvelope = {
  data?: Maybe<Array<IngredientUnitCreateManyRecipeSectionInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type IngredientUnitCreateManyUnitInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSectionId: Scalars['String'];
};

export type IngredientUnitCreateManyUnitInputEnvelope = {
  data?: Maybe<Array<IngredientUnitCreateManyUnitInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type IngredientUnitCreateNestedManyWithoutIngredientInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutIngredientInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutIngredientInput>>;
  createMany?: Maybe<IngredientUnitCreateManyIngredientInputEnvelope>;
};

export type IngredientUnitCreateNestedManyWithoutRecipeSectionInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutRecipeSectionInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutRecipeSectionInput>>;
  createMany?: Maybe<IngredientUnitCreateManyRecipeSectionInputEnvelope>;
};

export type IngredientUnitCreateNestedManyWithoutUnitInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutUnitInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutUnitInput>>;
  createMany?: Maybe<IngredientUnitCreateManyUnitInputEnvelope>;
};

export type IngredientUnitCreateOrConnectWithoutIngredientInput = {
  create: IngredientUnitCreateWithoutIngredientInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitCreateOrConnectWithoutRecipeSectionInput = {
  create: IngredientUnitCreateWithoutRecipeSectionInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitCreateOrConnectWithoutUnitInput = {
  create: IngredientUnitCreateWithoutUnitInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitCreateWithoutIngredientInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection: RecipeSectionCreateNestedOneWithoutIngredientsInput;
  unit?: Maybe<UnitSizeCreateNestedOneWithoutIngredientUnitInput>;
};

export type IngredientUnitCreateWithoutRecipeSectionInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientCreateNestedOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  unit?: Maybe<UnitSizeCreateNestedOneWithoutIngredientUnitInput>;
};

export type IngredientUnitCreateWithoutUnitInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientCreateNestedOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection: RecipeSectionCreateNestedOneWithoutIngredientsInput;
};

export type IngredientUnitListRelationFilter = {
  every?: Maybe<IngredientUnitWhereInput>;
  none?: Maybe<IngredientUnitWhereInput>;
  some?: Maybe<IngredientUnitWhereInput>;
};

export type IngredientUnitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type IngredientUnitOrderByWithRelationInput = {
  amount?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ingredient?: Maybe<IngredientOrderByWithRelationInput>;
  ingredientId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  recipeSection?: Maybe<RecipeSectionOrderByWithRelationInput>;
  recipeSectionId?: Maybe<SortOrder>;
  unit?: Maybe<UnitSizeOrderByWithRelationInput>;
  unitId?: Maybe<SortOrder>;
};

export type IngredientUnitScalarWhereInput = {
  AND?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  NOT?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  OR?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  amount?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  ingredientId?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  recipeSectionId?: Maybe<StringFilter>;
  unitId?: Maybe<StringNullableFilter>;
};

export type IngredientUnitUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientUpdateOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection?: Maybe<RecipeSectionUpdateOneRequiredWithoutIngredientsInput>;
  unit?: Maybe<UnitSizeUpdateOneWithoutIngredientUnitInput>;
};

export type IngredientUnitUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type IngredientUnitUpdateManyWithWhereWithoutIngredientInput = {
  data: IngredientUnitUpdateManyMutationInput;
  where: IngredientUnitScalarWhereInput;
};

export type IngredientUnitUpdateManyWithWhereWithoutRecipeSectionInput = {
  data: IngredientUnitUpdateManyMutationInput;
  where: IngredientUnitScalarWhereInput;
};

export type IngredientUnitUpdateManyWithWhereWithoutUnitInput = {
  data: IngredientUnitUpdateManyMutationInput;
  where: IngredientUnitScalarWhereInput;
};

export type IngredientUnitUpdateManyWithoutIngredientInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutIngredientInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutIngredientInput>>;
  createMany?: Maybe<IngredientUnitCreateManyIngredientInputEnvelope>;
  delete?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  disconnect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  set?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  update?: Maybe<Array<IngredientUnitUpdateWithWhereUniqueWithoutIngredientInput>>;
  updateMany?: Maybe<Array<IngredientUnitUpdateManyWithWhereWithoutIngredientInput>>;
  upsert?: Maybe<Array<IngredientUnitUpsertWithWhereUniqueWithoutIngredientInput>>;
};

export type IngredientUnitUpdateManyWithoutRecipeSectionInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutRecipeSectionInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutRecipeSectionInput>>;
  createMany?: Maybe<IngredientUnitCreateManyRecipeSectionInputEnvelope>;
  delete?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  disconnect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  set?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  update?: Maybe<Array<IngredientUnitUpdateWithWhereUniqueWithoutRecipeSectionInput>>;
  updateMany?: Maybe<Array<IngredientUnitUpdateManyWithWhereWithoutRecipeSectionInput>>;
  upsert?: Maybe<Array<IngredientUnitUpsertWithWhereUniqueWithoutRecipeSectionInput>>;
};

export type IngredientUnitUpdateManyWithoutUnitInput = {
  connect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IngredientUnitCreateOrConnectWithoutUnitInput>>;
  create?: Maybe<Array<IngredientUnitCreateWithoutUnitInput>>;
  createMany?: Maybe<IngredientUnitCreateManyUnitInputEnvelope>;
  delete?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<IngredientUnitScalarWhereInput>>;
  disconnect?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  set?: Maybe<Array<IngredientUnitWhereUniqueInput>>;
  update?: Maybe<Array<IngredientUnitUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: Maybe<Array<IngredientUnitUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: Maybe<Array<IngredientUnitUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type IngredientUnitUpdateWithWhereUniqueWithoutIngredientInput = {
  data: IngredientUnitUpdateWithoutIngredientInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitUpdateWithWhereUniqueWithoutRecipeSectionInput = {
  data: IngredientUnitUpdateWithoutRecipeSectionInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitUpdateWithWhereUniqueWithoutUnitInput = {
  data: IngredientUnitUpdateWithoutUnitInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitUpdateWithoutIngredientInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection?: Maybe<RecipeSectionUpdateOneRequiredWithoutIngredientsInput>;
  unit?: Maybe<UnitSizeUpdateOneWithoutIngredientUnitInput>;
};

export type IngredientUnitUpdateWithoutRecipeSectionInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientUpdateOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  unit?: Maybe<UnitSizeUpdateOneWithoutIngredientUnitInput>;
};

export type IngredientUnitUpdateWithoutUnitInput = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientUpdateOneWithoutIngredientUnitInput>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection?: Maybe<RecipeSectionUpdateOneRequiredWithoutIngredientsInput>;
};

export type IngredientUnitUpsertWithWhereUniqueWithoutIngredientInput = {
  create: IngredientUnitCreateWithoutIngredientInput;
  update: IngredientUnitUpdateWithoutIngredientInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitUpsertWithWhereUniqueWithoutRecipeSectionInput = {
  create: IngredientUnitCreateWithoutRecipeSectionInput;
  update: IngredientUnitUpdateWithoutRecipeSectionInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitUpsertWithWhereUniqueWithoutUnitInput = {
  create: IngredientUnitCreateWithoutUnitInput;
  update: IngredientUnitUpdateWithoutUnitInput;
  where: IngredientUnitWhereUniqueInput;
};

export type IngredientUnitWhereInput = {
  AND?: Maybe<Array<IngredientUnitWhereInput>>;
  NOT?: Maybe<Array<IngredientUnitWhereInput>>;
  OR?: Maybe<Array<IngredientUnitWhereInput>>;
  amount?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  ingredient?: Maybe<IngredientWhereInput>;
  ingredientId?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  recipeSection?: Maybe<RecipeSectionWhereInput>;
  recipeSectionId?: Maybe<StringFilter>;
  unit?: Maybe<UnitSizeWhereInput>;
  unitId?: Maybe<StringNullableFilter>;
};

export type IngredientUnitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IngredientUpdateInput = {
  IngredientUnit?: Maybe<IngredientUnitUpdateManyWithoutIngredientInput>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IngredientUpdateOneWithoutIngredientUnitInput = {
  connect?: Maybe<IngredientWhereUniqueInput>;
  connectOrCreate?: Maybe<IngredientCreateOrConnectWithoutIngredientUnitInput>;
  create?: Maybe<IngredientCreateWithoutIngredientUnitInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<IngredientUpdateWithoutIngredientUnitInput>;
  upsert?: Maybe<IngredientUpsertWithoutIngredientUnitInput>;
};

export type IngredientUpdateWithoutIngredientUnitInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IngredientUpsertWithoutIngredientUnitInput = {
  create: IngredientCreateWithoutIngredientUnitInput;
  update: IngredientUpdateWithoutIngredientUnitInput;
};

export type IngredientWhereInput = {
  AND?: Maybe<Array<IngredientWhereInput>>;
  IngredientUnit?: Maybe<IngredientUnitListRelationFilter>;
  NOT?: Maybe<Array<IngredientWhereInput>>;
  OR?: Maybe<Array<IngredientWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type IngredientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type JwtSubCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  sub: Scalars['String'];
};

export type JwtSubCreateManyUserInputEnvelope = {
  data?: Maybe<Array<JwtSubCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type JwtSubCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<JwtSubWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<JwtSubCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<JwtSubCreateWithoutUserInput>>;
  createMany?: Maybe<JwtSubCreateManyUserInputEnvelope>;
};

export type JwtSubCreateOrConnectWithoutUserInput = {
  create: JwtSubCreateWithoutUserInput;
  where: JwtSubWhereUniqueInput;
};

export type JwtSubCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  sub: Scalars['String'];
};

export type JwtSubListRelationFilter = {
  every?: Maybe<JwtSubWhereInput>;
  none?: Maybe<JwtSubWhereInput>;
  some?: Maybe<JwtSubWhereInput>;
};

export type JwtSubOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type JwtSubScalarWhereInput = {
  AND?: Maybe<Array<JwtSubScalarWhereInput>>;
  NOT?: Maybe<Array<JwtSubScalarWhereInput>>;
  OR?: Maybe<Array<JwtSubScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sub?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type JwtSubUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

export type JwtSubUpdateManyWithWhereWithoutUserInput = {
  data: JwtSubUpdateManyMutationInput;
  where: JwtSubScalarWhereInput;
};

export type JwtSubUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<JwtSubWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<JwtSubCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<JwtSubCreateWithoutUserInput>>;
  createMany?: Maybe<JwtSubCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<JwtSubWhereUniqueInput>>;
  deleteMany?: Maybe<Array<JwtSubScalarWhereInput>>;
  disconnect?: Maybe<Array<JwtSubWhereUniqueInput>>;
  set?: Maybe<Array<JwtSubWhereUniqueInput>>;
  update?: Maybe<Array<JwtSubUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<JwtSubUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<JwtSubUpsertWithWhereUniqueWithoutUserInput>>;
};

export type JwtSubUpdateWithWhereUniqueWithoutUserInput = {
  data: JwtSubUpdateWithoutUserInput;
  where: JwtSubWhereUniqueInput;
};

export type JwtSubUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

export type JwtSubUpsertWithWhereUniqueWithoutUserInput = {
  create: JwtSubCreateWithoutUserInput;
  update: JwtSubUpdateWithoutUserInput;
  where: JwtSubWhereUniqueInput;
};

export type JwtSubWhereInput = {
  AND?: Maybe<Array<JwtSubWhereInput>>;
  NOT?: Maybe<Array<JwtSubWhereInput>>;
  OR?: Maybe<Array<JwtSubWhereInput>>;
  id?: Maybe<StringFilter>;
  sub?: Maybe<StringFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type JwtSubWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneIngredient: Ingredient;
  createOneIngredientUnit: IngredientUnit;
  createOneRecipe: Recipe;
  createOneRecipeSection: RecipeSection;
  createOneStep: Step;
  createOneTag: Tag;
  createOneUnitSize: UnitSize;
  createOneUser: User;
  deleteOneIngredient?: Maybe<Ingredient>;
  deleteOneIngredientUnit?: Maybe<IngredientUnit>;
  deleteOneRecipe?: Maybe<Recipe>;
  deleteOneRecipeSection?: Maybe<RecipeSection>;
  deleteOneStep?: Maybe<Step>;
  deleteOneTag?: Maybe<Tag>;
  deleteOneUnitSize?: Maybe<UnitSize>;
  deleteOneUser?: Maybe<User>;
  findOrCreateIngredients: Array<Maybe<Ingredient>>;
  findOrCreateTags: Array<Maybe<Tag>>;
  updateOneIngredient?: Maybe<Ingredient>;
  updateOneIngredientUnit?: Maybe<IngredientUnit>;
  updateOneRecipe?: Maybe<Recipe>;
  updateOneRecipeSection?: Maybe<RecipeSection>;
  updateOneStep?: Maybe<Step>;
  updateOneTag?: Maybe<Tag>;
  updateOneUnitSize?: Maybe<UnitSize>;
  updateOneUser?: Maybe<User>;
};


export type MutationCreateOneIngredientArgs = {
  data: IngredientCreateInput;
};


export type MutationCreateOneIngredientUnitArgs = {
  data: IngredientUnitCreateInput;
};


export type MutationCreateOneRecipeArgs = {
  data: RecipeCreateInput;
};


export type MutationCreateOneRecipeSectionArgs = {
  data: RecipeSectionCreateInput;
};


export type MutationCreateOneStepArgs = {
  data: StepCreateInput;
};


export type MutationCreateOneTagArgs = {
  data: TagCreateInput;
};


export type MutationCreateOneUnitSizeArgs = {
  data: UnitSizeCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteOneIngredientArgs = {
  where: IngredientWhereUniqueInput;
};


export type MutationDeleteOneIngredientUnitArgs = {
  where: IngredientUnitWhereUniqueInput;
};


export type MutationDeleteOneRecipeArgs = {
  where: RecipeWhereUniqueInput;
};


export type MutationDeleteOneRecipeSectionArgs = {
  where: RecipeSectionWhereUniqueInput;
};


export type MutationDeleteOneStepArgs = {
  where: StepWhereUniqueInput;
};


export type MutationDeleteOneTagArgs = {
  where: TagWhereUniqueInput;
};


export type MutationDeleteOneUnitSizeArgs = {
  where: UnitSizeWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationFindOrCreateIngredientsArgs = {
  names: Array<Maybe<Scalars['String']>>;
};


export type MutationFindOrCreateTagsArgs = {
  names: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateOneIngredientArgs = {
  data: IngredientUpdateInput;
  where: IngredientWhereUniqueInput;
};


export type MutationUpdateOneIngredientUnitArgs = {
  data: IngredientUnitUpdateInput;
  where: IngredientUnitWhereUniqueInput;
};


export type MutationUpdateOneRecipeArgs = {
  data: RecipeUpdateInput;
  where: RecipeWhereUniqueInput;
};


export type MutationUpdateOneRecipeSectionArgs = {
  data: RecipeSectionUpdateInput;
  where: RecipeSectionWhereUniqueInput;
};


export type MutationUpdateOneStepArgs = {
  data: StepUpdateInput;
  where: StepWhereUniqueInput;
};


export type MutationUpdateOneTagArgs = {
  data: TagUpdateInput;
  where: TagWhereUniqueInput;
};


export type MutationUpdateOneUnitSizeArgs = {
  data: UnitSizeUpdateInput;
  where: UnitSizeWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBytesFilter = {
  equals?: Maybe<Scalars['Bytes']>;
  not?: Maybe<NestedBytesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumUnitTypeFilter = {
  equals?: Maybe<UnitType>;
  in?: Maybe<Array<UnitType>>;
  not?: Maybe<NestedEnumUnitTypeFilter>;
  notIn?: Maybe<Array<UnitType>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  filterRecipes?: Maybe<Array<Maybe<Recipe>>>;
  ingredient?: Maybe<Ingredient>;
  ingredientUnit?: Maybe<IngredientUnit>;
  ingredientUnits: Array<IngredientUnit>;
  ingredients: Array<Ingredient>;
  me?: Maybe<User>;
  recipe?: Maybe<Recipe>;
  recipeSection?: Maybe<RecipeSection>;
  recipeSections: Array<RecipeSection>;
  recipes: Array<Recipe>;
  step?: Maybe<Step>;
  steps: Array<Step>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  unitSize?: Maybe<UnitSize>;
  unitSizes: Array<UnitSize>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryFilterRecipesArgs = {
  searchString: Scalars['String'];
};


export type QueryIngredientArgs = {
  where: IngredientWhereUniqueInput;
};


export type QueryIngredientUnitArgs = {
  where: IngredientUnitWhereUniqueInput;
};


export type QueryIngredientUnitsArgs = {
  after?: Maybe<IngredientUnitWhereUniqueInput>;
  before?: Maybe<IngredientUnitWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<IngredientUnitOrderByWithRelationInput>>;
};


export type QueryIngredientsArgs = {
  after?: Maybe<IngredientWhereUniqueInput>;
  before?: Maybe<IngredientWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<IngredientOrderByWithRelationInput>>;
  where?: Maybe<IngredientWhereInput>;
};


export type QueryRecipeArgs = {
  where: RecipeWhereUniqueInput;
};


export type QueryRecipeSectionArgs = {
  where: RecipeSectionWhereUniqueInput;
};


export type QueryRecipeSectionsArgs = {
  after?: Maybe<RecipeSectionWhereUniqueInput>;
  before?: Maybe<RecipeSectionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<RecipeSectionOrderByWithRelationInput>>;
};


export type QueryRecipesArgs = {
  after?: Maybe<RecipeWhereUniqueInput>;
  before?: Maybe<RecipeWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<RecipeOrderByWithRelationInput>>;
  where?: Maybe<RecipeWhereInput>;
};


export type QueryStepArgs = {
  where: StepWhereUniqueInput;
};


export type QueryStepsArgs = {
  after?: Maybe<StepWhereUniqueInput>;
  before?: Maybe<StepWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StepOrderByWithRelationInput>>;
};


export type QueryTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TagOrderByWithRelationInput>>;
};


export type QueryUnitSizeArgs = {
  where: UnitSizeWhereUniqueInput;
};


export type QueryUnitSizesArgs = {
  after?: Maybe<UnitSizeWhereUniqueInput>;
  before?: Maybe<UnitSizeWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UnitSizeOrderByWithRelationInput>>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  after?: Maybe<UserWhereUniqueInput>;
  before?: Maybe<UserWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Recipe = {
  __typename?: 'Recipe';
  author: User;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  photo: Array<File>;
  sections: Array<RecipeSection>;
  source?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type RecipePhotoArgs = {
  after?: Maybe<FileWhereUniqueInput>;
  before?: Maybe<FileWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RecipeSectionsArgs = {
  after?: Maybe<RecipeSectionWhereUniqueInput>;
  before?: Maybe<RecipeSectionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<RecipeSectionOrderByWithRelationInput>>;
};


export type RecipeTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RecipeCreateInput = {
  author: UserCreateNestedOneWithoutRecipesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileCreateNestedManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionCreateNestedManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagCreateNestedManyWithoutRecipesInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeCreateManyAuthorInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeCreateManyAuthorInputEnvelope = {
  data?: Maybe<Array<RecipeCreateManyAuthorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecipeCreateNestedManyWithoutAuthorInput = {
  connect?: Maybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<RecipeCreateWithoutAuthorInput>>;
  createMany?: Maybe<RecipeCreateManyAuthorInputEnvelope>;
};

export type RecipeCreateNestedManyWithoutTagsInput = {
  connect?: Maybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<RecipeCreateWithoutTagsInput>>;
};

export type RecipeCreateNestedOneWithoutPhotoInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeCreateOrConnectWithoutPhotoInput>;
  create?: Maybe<RecipeCreateWithoutPhotoInput>;
};

export type RecipeCreateNestedOneWithoutSectionsInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeCreateOrConnectWithoutSectionsInput>;
  create?: Maybe<RecipeCreateWithoutSectionsInput>;
};

export type RecipeCreateOrConnectWithoutAuthorInput = {
  create: RecipeCreateWithoutAuthorInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutPhotoInput = {
  create: RecipeCreateWithoutPhotoInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutSectionsInput = {
  create: RecipeCreateWithoutSectionsInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutTagsInput = {
  create: RecipeCreateWithoutTagsInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateWithoutAuthorInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileCreateNestedManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionCreateNestedManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagCreateNestedManyWithoutRecipesInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeCreateWithoutPhotoInput = {
  author: UserCreateNestedOneWithoutRecipesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  sections?: Maybe<RecipeSectionCreateNestedManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagCreateNestedManyWithoutRecipesInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeCreateWithoutSectionsInput = {
  author: UserCreateNestedOneWithoutRecipesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileCreateNestedManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagCreateNestedManyWithoutRecipesInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeCreateWithoutTagsInput = {
  author: UserCreateNestedOneWithoutRecipesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileCreateNestedManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionCreateNestedManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeListRelationFilter = {
  every?: Maybe<RecipeWhereInput>;
  none?: Maybe<RecipeWhereInput>;
  some?: Maybe<RecipeWhereInput>;
};

export type RecipeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type RecipeOrderByWithRelationInput = {
  author?: Maybe<UserOrderByWithRelationInput>;
  authorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  photo?: Maybe<FileOrderByRelationAggregateInput>;
  sections?: Maybe<RecipeSectionOrderByRelationAggregateInput>;
  source?: Maybe<SortOrder>;
  tags?: Maybe<TagOrderByRelationAggregateInput>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RecipeScalarWhereInput = {
  AND?: Maybe<Array<RecipeScalarWhereInput>>;
  NOT?: Maybe<Array<RecipeScalarWhereInput>>;
  OR?: Maybe<Array<RecipeScalarWhereInput>>;
  authorId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  source?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecipeSection = {
  __typename?: 'RecipeSection';
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ingredients: Array<IngredientUnit>;
  name: Scalars['String'];
  order: Scalars['Int'];
  prepTimeMinutes: Scalars['Int'];
  recipe: Recipe;
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
  steps: Array<Step>;
};


export type RecipeSectionIngredientsArgs = {
  after?: Maybe<IngredientUnitWhereUniqueInput>;
  before?: Maybe<IngredientUnitWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<IngredientUnitOrderByWithRelationInput>>;
};


export type RecipeSectionStepsArgs = {
  after?: Maybe<StepWhereUniqueInput>;
  before?: Maybe<StepWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StepOrderByWithRelationInput>>;
};

export type RecipeSectionCreateInput = {
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitCreateNestedManyWithoutRecipeSectionInput>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes: Scalars['Int'];
  recipe: RecipeCreateNestedOneWithoutSectionsInput;
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
  steps?: Maybe<StepCreateNestedManyWithoutRecipeSectionInput>;
};

export type RecipeSectionCreateManyRecipeInput = {
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes: Scalars['Int'];
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
};

export type RecipeSectionCreateManyRecipeInputEnvelope = {
  data?: Maybe<Array<RecipeSectionCreateManyRecipeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecipeSectionCreateNestedManyWithoutRecipeInput = {
  connect?: Maybe<Array<RecipeSectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeSectionCreateOrConnectWithoutRecipeInput>>;
  create?: Maybe<Array<RecipeSectionCreateWithoutRecipeInput>>;
  createMany?: Maybe<RecipeSectionCreateManyRecipeInputEnvelope>;
};

export type RecipeSectionCreateNestedOneWithoutIngredientsInput = {
  connect?: Maybe<RecipeSectionWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeSectionCreateOrConnectWithoutIngredientsInput>;
  create?: Maybe<RecipeSectionCreateWithoutIngredientsInput>;
};

export type RecipeSectionCreateNestedOneWithoutStepsInput = {
  connect?: Maybe<RecipeSectionWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeSectionCreateOrConnectWithoutStepsInput>;
  create?: Maybe<RecipeSectionCreateWithoutStepsInput>;
};

export type RecipeSectionCreateOrConnectWithoutIngredientsInput = {
  create: RecipeSectionCreateWithoutIngredientsInput;
  where: RecipeSectionWhereUniqueInput;
};

export type RecipeSectionCreateOrConnectWithoutRecipeInput = {
  create: RecipeSectionCreateWithoutRecipeInput;
  where: RecipeSectionWhereUniqueInput;
};

export type RecipeSectionCreateOrConnectWithoutStepsInput = {
  create: RecipeSectionCreateWithoutStepsInput;
  where: RecipeSectionWhereUniqueInput;
};

export type RecipeSectionCreateWithoutIngredientsInput = {
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes: Scalars['Int'];
  recipe: RecipeCreateNestedOneWithoutSectionsInput;
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
  steps?: Maybe<StepCreateNestedManyWithoutRecipeSectionInput>;
};

export type RecipeSectionCreateWithoutRecipeInput = {
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitCreateNestedManyWithoutRecipeSectionInput>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes: Scalars['Int'];
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
  steps?: Maybe<StepCreateNestedManyWithoutRecipeSectionInput>;
};

export type RecipeSectionCreateWithoutStepsInput = {
  cookTimeMinutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitCreateNestedManyWithoutRecipeSectionInput>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes: Scalars['Int'];
  recipe: RecipeCreateNestedOneWithoutSectionsInput;
  servingUnit: Scalars['String'];
  servings: Scalars['Int'];
};

export type RecipeSectionListRelationFilter = {
  every?: Maybe<RecipeSectionWhereInput>;
  none?: Maybe<RecipeSectionWhereInput>;
  some?: Maybe<RecipeSectionWhereInput>;
};

export type RecipeSectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type RecipeSectionOrderByWithRelationInput = {
  cookTimeMinutes?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ingredients?: Maybe<IngredientUnitOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  prepTimeMinutes?: Maybe<SortOrder>;
  recipe?: Maybe<RecipeOrderByWithRelationInput>;
  recipeId?: Maybe<SortOrder>;
  servingUnit?: Maybe<SortOrder>;
  servings?: Maybe<SortOrder>;
  steps?: Maybe<StepOrderByRelationAggregateInput>;
};

export type RecipeSectionScalarWhereInput = {
  AND?: Maybe<Array<RecipeSectionScalarWhereInput>>;
  NOT?: Maybe<Array<RecipeSectionScalarWhereInput>>;
  OR?: Maybe<Array<RecipeSectionScalarWhereInput>>;
  cookTimeMinutes?: Maybe<IntFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  prepTimeMinutes?: Maybe<IntFilter>;
  recipeId?: Maybe<StringFilter>;
  servingUnit?: Maybe<StringFilter>;
  servings?: Maybe<IntFilter>;
};

export type RecipeSectionUpdateInput = {
  cookTimeMinutes?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitUpdateManyWithoutRecipeSectionInput>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes?: Maybe<Scalars['Int']>;
  recipe?: Maybe<RecipeUpdateOneRequiredWithoutSectionsInput>;
  servingUnit?: Maybe<Scalars['String']>;
  servings?: Maybe<Scalars['Int']>;
  steps?: Maybe<StepUpdateManyWithoutRecipeSectionInput>;
};

export type RecipeSectionUpdateManyMutationInput = {
  cookTimeMinutes?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes?: Maybe<Scalars['Int']>;
  servingUnit?: Maybe<Scalars['String']>;
  servings?: Maybe<Scalars['Int']>;
};

export type RecipeSectionUpdateManyWithWhereWithoutRecipeInput = {
  data: RecipeSectionUpdateManyMutationInput;
  where: RecipeSectionScalarWhereInput;
};

export type RecipeSectionUpdateManyWithoutRecipeInput = {
  connect?: Maybe<Array<RecipeSectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeSectionCreateOrConnectWithoutRecipeInput>>;
  create?: Maybe<Array<RecipeSectionCreateWithoutRecipeInput>>;
  createMany?: Maybe<RecipeSectionCreateManyRecipeInputEnvelope>;
  delete?: Maybe<Array<RecipeSectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecipeSectionScalarWhereInput>>;
  disconnect?: Maybe<Array<RecipeSectionWhereUniqueInput>>;
  set?: Maybe<Array<RecipeSectionWhereUniqueInput>>;
  update?: Maybe<Array<RecipeSectionUpdateWithWhereUniqueWithoutRecipeInput>>;
  updateMany?: Maybe<Array<RecipeSectionUpdateManyWithWhereWithoutRecipeInput>>;
  upsert?: Maybe<Array<RecipeSectionUpsertWithWhereUniqueWithoutRecipeInput>>;
};

export type RecipeSectionUpdateOneRequiredWithoutIngredientsInput = {
  connect?: Maybe<RecipeSectionWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeSectionCreateOrConnectWithoutIngredientsInput>;
  create?: Maybe<RecipeSectionCreateWithoutIngredientsInput>;
  update?: Maybe<RecipeSectionUpdateWithoutIngredientsInput>;
  upsert?: Maybe<RecipeSectionUpsertWithoutIngredientsInput>;
};

export type RecipeSectionUpdateOneRequiredWithoutStepsInput = {
  connect?: Maybe<RecipeSectionWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeSectionCreateOrConnectWithoutStepsInput>;
  create?: Maybe<RecipeSectionCreateWithoutStepsInput>;
  update?: Maybe<RecipeSectionUpdateWithoutStepsInput>;
  upsert?: Maybe<RecipeSectionUpsertWithoutStepsInput>;
};

export type RecipeSectionUpdateWithWhereUniqueWithoutRecipeInput = {
  data: RecipeSectionUpdateWithoutRecipeInput;
  where: RecipeSectionWhereUniqueInput;
};

export type RecipeSectionUpdateWithoutIngredientsInput = {
  cookTimeMinutes?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes?: Maybe<Scalars['Int']>;
  recipe?: Maybe<RecipeUpdateOneRequiredWithoutSectionsInput>;
  servingUnit?: Maybe<Scalars['String']>;
  servings?: Maybe<Scalars['Int']>;
  steps?: Maybe<StepUpdateManyWithoutRecipeSectionInput>;
};

export type RecipeSectionUpdateWithoutRecipeInput = {
  cookTimeMinutes?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitUpdateManyWithoutRecipeSectionInput>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes?: Maybe<Scalars['Int']>;
  servingUnit?: Maybe<Scalars['String']>;
  servings?: Maybe<Scalars['Int']>;
  steps?: Maybe<StepUpdateManyWithoutRecipeSectionInput>;
};

export type RecipeSectionUpdateWithoutStepsInput = {
  cookTimeMinutes?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ingredients?: Maybe<IngredientUnitUpdateManyWithoutRecipeSectionInput>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepTimeMinutes?: Maybe<Scalars['Int']>;
  recipe?: Maybe<RecipeUpdateOneRequiredWithoutSectionsInput>;
  servingUnit?: Maybe<Scalars['String']>;
  servings?: Maybe<Scalars['Int']>;
};

export type RecipeSectionUpsertWithWhereUniqueWithoutRecipeInput = {
  create: RecipeSectionCreateWithoutRecipeInput;
  update: RecipeSectionUpdateWithoutRecipeInput;
  where: RecipeSectionWhereUniqueInput;
};

export type RecipeSectionUpsertWithoutIngredientsInput = {
  create: RecipeSectionCreateWithoutIngredientsInput;
  update: RecipeSectionUpdateWithoutIngredientsInput;
};

export type RecipeSectionUpsertWithoutStepsInput = {
  create: RecipeSectionCreateWithoutStepsInput;
  update: RecipeSectionUpdateWithoutStepsInput;
};

export type RecipeSectionWhereInput = {
  AND?: Maybe<Array<RecipeSectionWhereInput>>;
  NOT?: Maybe<Array<RecipeSectionWhereInput>>;
  OR?: Maybe<Array<RecipeSectionWhereInput>>;
  cookTimeMinutes?: Maybe<IntFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  ingredients?: Maybe<IngredientUnitListRelationFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  prepTimeMinutes?: Maybe<IntFilter>;
  recipe?: Maybe<RecipeWhereInput>;
  recipeId?: Maybe<StringFilter>;
  servingUnit?: Maybe<StringFilter>;
  servings?: Maybe<IntFilter>;
  steps?: Maybe<StepListRelationFilter>;
};

export type RecipeSectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type RecipeUpdateInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutRecipesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileUpdateManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionUpdateManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagUpdateManyWithoutRecipesInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpdateManyWithWhereWithoutAuthorInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithWhereWithoutTagsInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithoutAuthorInput = {
  connect?: Maybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<RecipeCreateWithoutAuthorInput>>;
  createMany?: Maybe<RecipeCreateManyAuthorInputEnvelope>;
  delete?: Maybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecipeScalarWhereInput>>;
  disconnect?: Maybe<Array<RecipeWhereUniqueInput>>;
  set?: Maybe<Array<RecipeWhereUniqueInput>>;
  update?: Maybe<Array<RecipeUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<RecipeUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: Maybe<Array<RecipeUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type RecipeUpdateManyWithoutTagsInput = {
  connect?: Maybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecipeCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<RecipeCreateWithoutTagsInput>>;
  delete?: Maybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecipeScalarWhereInput>>;
  disconnect?: Maybe<Array<RecipeWhereUniqueInput>>;
  set?: Maybe<Array<RecipeWhereUniqueInput>>;
  update?: Maybe<Array<RecipeUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: Maybe<Array<RecipeUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: Maybe<Array<RecipeUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type RecipeUpdateOneRequiredWithoutPhotoInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeCreateOrConnectWithoutPhotoInput>;
  create?: Maybe<RecipeCreateWithoutPhotoInput>;
  update?: Maybe<RecipeUpdateWithoutPhotoInput>;
  upsert?: Maybe<RecipeUpsertWithoutPhotoInput>;
};

export type RecipeUpdateOneRequiredWithoutSectionsInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  connectOrCreate?: Maybe<RecipeCreateOrConnectWithoutSectionsInput>;
  create?: Maybe<RecipeCreateWithoutSectionsInput>;
  update?: Maybe<RecipeUpdateWithoutSectionsInput>;
  upsert?: Maybe<RecipeUpsertWithoutSectionsInput>;
};

export type RecipeUpdateWithWhereUniqueWithoutAuthorInput = {
  data: RecipeUpdateWithoutAuthorInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithWhereUniqueWithoutTagsInput = {
  data: RecipeUpdateWithoutTagsInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithoutAuthorInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileUpdateManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionUpdateManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagUpdateManyWithoutRecipesInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpdateWithoutPhotoInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutRecipesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  sections?: Maybe<RecipeSectionUpdateManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagUpdateManyWithoutRecipesInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpdateWithoutSectionsInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutRecipesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileUpdateManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagUpdateManyWithoutRecipesInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpdateWithoutTagsInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutRecipesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  photo?: Maybe<FileUpdateManyWithoutRecipeInput>;
  sections?: Maybe<RecipeSectionUpdateManyWithoutRecipeInput>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecipeUpsertWithWhereUniqueWithoutAuthorInput = {
  create: RecipeCreateWithoutAuthorInput;
  update: RecipeUpdateWithoutAuthorInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithWhereUniqueWithoutTagsInput = {
  create: RecipeCreateWithoutTagsInput;
  update: RecipeUpdateWithoutTagsInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithoutPhotoInput = {
  create: RecipeCreateWithoutPhotoInput;
  update: RecipeUpdateWithoutPhotoInput;
};

export type RecipeUpsertWithoutSectionsInput = {
  create: RecipeCreateWithoutSectionsInput;
  update: RecipeUpdateWithoutSectionsInput;
};

export type RecipeWhereInput = {
  AND?: Maybe<Array<RecipeWhereInput>>;
  NOT?: Maybe<Array<RecipeWhereInput>>;
  OR?: Maybe<Array<RecipeWhereInput>>;
  author?: Maybe<UserWhereInput>;
  authorId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  photo?: Maybe<FileListRelationFilter>;
  sections?: Maybe<RecipeSectionListRelationFilter>;
  source?: Maybe<StringNullableFilter>;
  tags?: Maybe<TagListRelationFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecipeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Step = {
  __typename?: 'Step';
  description: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  photo?: Maybe<File>;
  recipeSection: RecipeSection;
};

export type StepCreateInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photo?: Maybe<FileCreateNestedOneWithoutStepInput>;
  recipeSection: RecipeSectionCreateNestedOneWithoutStepsInput;
};

export type StepCreateManyPhotoInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSectionId: Scalars['String'];
};

export type StepCreateManyPhotoInputEnvelope = {
  data?: Maybe<Array<StepCreateManyPhotoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StepCreateManyRecipeSectionInput = {
  description: Scalars['String'];
  fileId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type StepCreateManyRecipeSectionInputEnvelope = {
  data?: Maybe<Array<StepCreateManyRecipeSectionInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StepCreateNestedManyWithoutPhotoInput = {
  connect?: Maybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StepCreateOrConnectWithoutPhotoInput>>;
  create?: Maybe<Array<StepCreateWithoutPhotoInput>>;
  createMany?: Maybe<StepCreateManyPhotoInputEnvelope>;
};

export type StepCreateNestedManyWithoutRecipeSectionInput = {
  connect?: Maybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StepCreateOrConnectWithoutRecipeSectionInput>>;
  create?: Maybe<Array<StepCreateWithoutRecipeSectionInput>>;
  createMany?: Maybe<StepCreateManyRecipeSectionInputEnvelope>;
};

export type StepCreateOrConnectWithoutPhotoInput = {
  create: StepCreateWithoutPhotoInput;
  where: StepWhereUniqueInput;
};

export type StepCreateOrConnectWithoutRecipeSectionInput = {
  create: StepCreateWithoutRecipeSectionInput;
  where: StepWhereUniqueInput;
};

export type StepCreateWithoutPhotoInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection: RecipeSectionCreateNestedOneWithoutStepsInput;
};

export type StepCreateWithoutRecipeSectionInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photo?: Maybe<FileCreateNestedOneWithoutStepInput>;
};

export type StepListRelationFilter = {
  every?: Maybe<StepWhereInput>;
  none?: Maybe<StepWhereInput>;
  some?: Maybe<StepWhereInput>;
};

export type StepOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type StepOrderByWithRelationInput = {
  description?: Maybe<SortOrder>;
  fileId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  photo?: Maybe<FileOrderByWithRelationInput>;
  recipeSection?: Maybe<RecipeSectionOrderByWithRelationInput>;
  recipeSectionId?: Maybe<SortOrder>;
};

export type StepScalarWhereInput = {
  AND?: Maybe<Array<StepScalarWhereInput>>;
  NOT?: Maybe<Array<StepScalarWhereInput>>;
  OR?: Maybe<Array<StepScalarWhereInput>>;
  description?: Maybe<StringFilter>;
  fileId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  recipeSectionId?: Maybe<StringFilter>;
};

export type StepUpdateInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photo?: Maybe<FileUpdateOneWithoutStepInput>;
  recipeSection?: Maybe<RecipeSectionUpdateOneRequiredWithoutStepsInput>;
};

export type StepUpdateManyMutationInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type StepUpdateManyWithWhereWithoutPhotoInput = {
  data: StepUpdateManyMutationInput;
  where: StepScalarWhereInput;
};

export type StepUpdateManyWithWhereWithoutRecipeSectionInput = {
  data: StepUpdateManyMutationInput;
  where: StepScalarWhereInput;
};

export type StepUpdateManyWithoutPhotoInput = {
  connect?: Maybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StepCreateOrConnectWithoutPhotoInput>>;
  create?: Maybe<Array<StepCreateWithoutPhotoInput>>;
  createMany?: Maybe<StepCreateManyPhotoInputEnvelope>;
  delete?: Maybe<Array<StepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StepScalarWhereInput>>;
  disconnect?: Maybe<Array<StepWhereUniqueInput>>;
  set?: Maybe<Array<StepWhereUniqueInput>>;
  update?: Maybe<Array<StepUpdateWithWhereUniqueWithoutPhotoInput>>;
  updateMany?: Maybe<Array<StepUpdateManyWithWhereWithoutPhotoInput>>;
  upsert?: Maybe<Array<StepUpsertWithWhereUniqueWithoutPhotoInput>>;
};

export type StepUpdateManyWithoutRecipeSectionInput = {
  connect?: Maybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StepCreateOrConnectWithoutRecipeSectionInput>>;
  create?: Maybe<Array<StepCreateWithoutRecipeSectionInput>>;
  createMany?: Maybe<StepCreateManyRecipeSectionInputEnvelope>;
  delete?: Maybe<Array<StepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StepScalarWhereInput>>;
  disconnect?: Maybe<Array<StepWhereUniqueInput>>;
  set?: Maybe<Array<StepWhereUniqueInput>>;
  update?: Maybe<Array<StepUpdateWithWhereUniqueWithoutRecipeSectionInput>>;
  updateMany?: Maybe<Array<StepUpdateManyWithWhereWithoutRecipeSectionInput>>;
  upsert?: Maybe<Array<StepUpsertWithWhereUniqueWithoutRecipeSectionInput>>;
};

export type StepUpdateWithWhereUniqueWithoutPhotoInput = {
  data: StepUpdateWithoutPhotoInput;
  where: StepWhereUniqueInput;
};

export type StepUpdateWithWhereUniqueWithoutRecipeSectionInput = {
  data: StepUpdateWithoutRecipeSectionInput;
  where: StepWhereUniqueInput;
};

export type StepUpdateWithoutPhotoInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  recipeSection?: Maybe<RecipeSectionUpdateOneRequiredWithoutStepsInput>;
};

export type StepUpdateWithoutRecipeSectionInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photo?: Maybe<FileUpdateOneWithoutStepInput>;
};

export type StepUpsertWithWhereUniqueWithoutPhotoInput = {
  create: StepCreateWithoutPhotoInput;
  update: StepUpdateWithoutPhotoInput;
  where: StepWhereUniqueInput;
};

export type StepUpsertWithWhereUniqueWithoutRecipeSectionInput = {
  create: StepCreateWithoutRecipeSectionInput;
  update: StepUpdateWithoutRecipeSectionInput;
  where: StepWhereUniqueInput;
};

export type StepWhereInput = {
  AND?: Maybe<Array<StepWhereInput>>;
  NOT?: Maybe<Array<StepWhereInput>>;
  OR?: Maybe<Array<StepWhereInput>>;
  description?: Maybe<StringFilter>;
  fileId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  photo?: Maybe<FileWhereInput>;
  recipeSection?: Maybe<RecipeSectionWhereInput>;
  recipeSectionId?: Maybe<StringFilter>;
};

export type StepWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name: Scalars['String'];
  recipes: Array<Recipe>;
};


export type TagRecipesArgs = {
  after?: Maybe<RecipeWhereUniqueInput>;
  before?: Maybe<RecipeWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TagCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recipes?: Maybe<RecipeCreateNestedManyWithoutTagsInput>;
};

export type TagCreateNestedManyWithoutRecipesInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutRecipesInput>>;
  create?: Maybe<Array<TagCreateWithoutRecipesInput>>;
};

export type TagCreateOrConnectWithoutRecipesInput = {
  create: TagCreateWithoutRecipesInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutRecipesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TagListRelationFilter = {
  every?: Maybe<TagWhereInput>;
  none?: Maybe<TagWhereInput>;
  some?: Maybe<TagWhereInput>;
};

export type TagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TagOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  recipes?: Maybe<RecipeOrderByRelationAggregateInput>;
};

export type TagScalarWhereInput = {
  AND?: Maybe<Array<TagScalarWhereInput>>;
  NOT?: Maybe<Array<TagScalarWhereInput>>;
  OR?: Maybe<Array<TagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type TagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recipes?: Maybe<RecipeUpdateManyWithoutTagsInput>;
};

export type TagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TagUpdateManyWithWhereWithoutRecipesInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutRecipesInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutRecipesInput>>;
  create?: Maybe<Array<TagCreateWithoutRecipesInput>>;
  delete?: Maybe<Array<TagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TagScalarWhereInput>>;
  disconnect?: Maybe<Array<TagWhereUniqueInput>>;
  set?: Maybe<Array<TagWhereUniqueInput>>;
  update?: Maybe<Array<TagUpdateWithWhereUniqueWithoutRecipesInput>>;
  updateMany?: Maybe<Array<TagUpdateManyWithWhereWithoutRecipesInput>>;
  upsert?: Maybe<Array<TagUpsertWithWhereUniqueWithoutRecipesInput>>;
};

export type TagUpdateWithWhereUniqueWithoutRecipesInput = {
  data: TagUpdateWithoutRecipesInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutRecipesInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TagUpsertWithWhereUniqueWithoutRecipesInput = {
  create: TagCreateWithoutRecipesInput;
  update: TagUpdateWithoutRecipesInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: Maybe<Array<TagWhereInput>>;
  NOT?: Maybe<Array<TagWhereInput>>;
  OR?: Maybe<Array<TagWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  recipes?: Maybe<RecipeListRelationFilter>;
};

export type TagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnitSize = {
  __typename?: 'UnitSize';
  id: Scalars['String'];
  name: Scalars['String'];
  plural: Scalars['String'];
  unitType: UnitType;
};

export type UnitSizeCreateInput = {
  IngredientUnit?: Maybe<IngredientUnitCreateNestedManyWithoutUnitInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  plural: Scalars['String'];
  unitType?: Maybe<UnitType>;
};

export type UnitSizeCreateNestedOneWithoutIngredientUnitInput = {
  connect?: Maybe<UnitSizeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitSizeCreateOrConnectWithoutIngredientUnitInput>;
  create?: Maybe<UnitSizeCreateWithoutIngredientUnitInput>;
};

export type UnitSizeCreateOrConnectWithoutIngredientUnitInput = {
  create: UnitSizeCreateWithoutIngredientUnitInput;
  where: UnitSizeWhereUniqueInput;
};

export type UnitSizeCreateWithoutIngredientUnitInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  plural: Scalars['String'];
  unitType?: Maybe<UnitType>;
};

export type UnitSizeOrderByWithRelationInput = {
  IngredientUnit?: Maybe<IngredientUnitOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  plural?: Maybe<SortOrder>;
  unitType?: Maybe<SortOrder>;
};

export type UnitSizeUpdateInput = {
  IngredientUnit?: Maybe<IngredientUnitUpdateManyWithoutUnitInput>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plural?: Maybe<Scalars['String']>;
  unitType?: Maybe<UnitType>;
};

export type UnitSizeUpdateOneWithoutIngredientUnitInput = {
  connect?: Maybe<UnitSizeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitSizeCreateOrConnectWithoutIngredientUnitInput>;
  create?: Maybe<UnitSizeCreateWithoutIngredientUnitInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UnitSizeUpdateWithoutIngredientUnitInput>;
  upsert?: Maybe<UnitSizeUpsertWithoutIngredientUnitInput>;
};

export type UnitSizeUpdateWithoutIngredientUnitInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plural?: Maybe<Scalars['String']>;
  unitType?: Maybe<UnitType>;
};

export type UnitSizeUpsertWithoutIngredientUnitInput = {
  create: UnitSizeCreateWithoutIngredientUnitInput;
  update: UnitSizeUpdateWithoutIngredientUnitInput;
};

export type UnitSizeWhereInput = {
  AND?: Maybe<Array<UnitSizeWhereInput>>;
  IngredientUnit?: Maybe<IngredientUnitListRelationFilter>;
  NOT?: Maybe<Array<UnitSizeWhereInput>>;
  OR?: Maybe<Array<UnitSizeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  plural?: Maybe<StringFilter>;
  unitType?: Maybe<EnumUnitTypeFilter>;
};

export type UnitSizeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum UnitType {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  recipes: Array<Recipe>;
};


export type UserRecipesArgs = {
  after?: Maybe<RecipeWhereUniqueInput>;
  before?: Maybe<RecipeWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCreateInput = {
  File?: Maybe<FileCreateNestedManyWithoutOwnerInput>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubCreateNestedManyWithoutUserInput>;
  name: Scalars['String'];
  recipes?: Maybe<RecipeCreateNestedManyWithoutAuthorInput>;
};

export type UserCreateNestedOneWithoutFileInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFileInput>;
  create?: Maybe<UserCreateWithoutFileInput>;
};

export type UserCreateNestedOneWithoutRecipesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRecipesInput>;
  create?: Maybe<UserCreateWithoutRecipesInput>;
};

export type UserCreateOrConnectWithoutFileInput = {
  create: UserCreateWithoutFileInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRecipesInput = {
  create: UserCreateWithoutRecipesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutFileInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubCreateNestedManyWithoutUserInput>;
  name: Scalars['String'];
  recipes?: Maybe<RecipeCreateNestedManyWithoutAuthorInput>;
};

export type UserCreateWithoutRecipesInput = {
  File?: Maybe<FileCreateNestedManyWithoutOwnerInput>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubCreateNestedManyWithoutUserInput>;
  name: Scalars['String'];
};

export type UserOrderByWithRelationInput = {
  File?: Maybe<FileOrderByRelationAggregateInput>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  jwtSub?: Maybe<JwtSubOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  recipes?: Maybe<RecipeOrderByRelationAggregateInput>;
};

export type UserUpdateInput = {
  File?: Maybe<FileUpdateManyWithoutOwnerInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubUpdateManyWithoutUserInput>;
  name?: Maybe<Scalars['String']>;
  recipes?: Maybe<RecipeUpdateManyWithoutAuthorInput>;
};

export type UserUpdateOneRequiredWithoutFileInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFileInput>;
  create?: Maybe<UserCreateWithoutFileInput>;
  update?: Maybe<UserUpdateWithoutFileInput>;
  upsert?: Maybe<UserUpsertWithoutFileInput>;
};

export type UserUpdateOneRequiredWithoutRecipesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRecipesInput>;
  create?: Maybe<UserCreateWithoutRecipesInput>;
  update?: Maybe<UserUpdateWithoutRecipesInput>;
  upsert?: Maybe<UserUpsertWithoutRecipesInput>;
};

export type UserUpdateWithoutFileInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubUpdateManyWithoutUserInput>;
  name?: Maybe<Scalars['String']>;
  recipes?: Maybe<RecipeUpdateManyWithoutAuthorInput>;
};

export type UserUpdateWithoutRecipesInput = {
  File?: Maybe<FileUpdateManyWithoutOwnerInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jwtSub?: Maybe<JwtSubUpdateManyWithoutUserInput>;
  name?: Maybe<Scalars['String']>;
};

export type UserUpsertWithoutFileInput = {
  create: UserCreateWithoutFileInput;
  update: UserUpdateWithoutFileInput;
};

export type UserUpsertWithoutRecipesInput = {
  create: UserCreateWithoutRecipesInput;
  update: UserUpdateWithoutRecipesInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  File?: Maybe<FileListRelationFilter>;
  NOT?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  email?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  jwtSub?: Maybe<JwtSubListRelationFilter>;
  name?: Maybe<StringFilter>;
  recipes?: Maybe<RecipeListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CreateOneIngredientUnitMutationVariables = Exact<{
  order?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  ingredient?: Maybe<IngredientCreateNestedOneWithoutIngredientUnitInput>;
  amount?: Maybe<Scalars['Float']>;
  recipeSectionId: Scalars['String'];
  unit?: Maybe<UnitSizeCreateNestedOneWithoutIngredientUnitInput>;
}>;


export type CreateOneIngredientUnitMutation = { __typename?: 'Mutation', createOneIngredientUnit: { __typename?: 'IngredientUnit', id: string, order: number, notes?: string | null | undefined, amount?: number | null | undefined, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined } };

export type CreateOneRecipeMutationVariables = Exact<{
  title: Scalars['String'];
  author: UserCreateNestedOneWithoutRecipesInput;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagCreateNestedManyWithoutRecipesInput>;
  sections?: Maybe<RecipeSectionCreateNestedManyWithoutRecipeInput>;
}>;


export type CreateOneRecipeMutation = { __typename?: 'Mutation', createOneRecipe: { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> } };

export type CreateOneRecipeSectionMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prepTimeMinutes: Scalars['Int'];
  cookTimeMinutes: Scalars['Int'];
  ingredients?: Maybe<IngredientUnitCreateNestedManyWithoutRecipeSectionInput>;
  servings: Scalars['Int'];
  servingUnit: Scalars['String'];
  steps?: Maybe<StepCreateNestedManyWithoutRecipeSectionInput>;
  recipe: RecipeCreateNestedOneWithoutSectionsInput;
}>;


export type CreateOneRecipeSectionMutation = { __typename?: 'Mutation', createOneRecipeSection: { __typename?: 'RecipeSection', id: string, order: number, cookTimeMinutes: number, prepTimeMinutes: number, description?: string | null | undefined, name: string, servingUnit: string, servings: number, ingredients: Array<{ __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined }>, steps: Array<{ __typename?: 'Step', id: string, description: string, order: number }>, recipe: { __typename?: 'Recipe', id: string } } };

export type CreateOneStepMutationVariables = Exact<{
  order?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  recipeSection: RecipeSectionCreateNestedOneWithoutStepsInput;
}>;


export type CreateOneStepMutation = { __typename?: 'Mutation', createOneStep: { __typename?: 'Step', id: string, order: number, description: string } };

export type DeleteOneIngredientUnitMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOneIngredientUnitMutation = { __typename?: 'Mutation', deleteOneIngredientUnit?: { __typename?: 'IngredientUnit', id: string } | null | undefined };

export type DeleteOneRecipeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOneRecipeMutation = { __typename?: 'Mutation', deleteOneRecipe?: { __typename?: 'Recipe', id: string } | null | undefined };

export type DeleteOneRecipeSectionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOneRecipeSectionMutation = { __typename?: 'Mutation', deleteOneRecipeSection?: { __typename?: 'RecipeSection', id: string } | null | undefined };

export type DeleteOneStepMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOneStepMutation = { __typename?: 'Mutation', deleteOneStep?: { __typename?: 'Step', id: string } | null | undefined };

export type FilterRecipesQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type FilterRecipesQuery = { __typename?: 'Query', filterRecipes?: Array<{ __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined } | null | undefined> | null | undefined };

export type FindOrCreateIngredientsMutationVariables = Exact<{
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type FindOrCreateIngredientsMutation = { __typename?: 'Mutation', findOrCreateIngredients: Array<{ __typename?: 'Ingredient', id: string, name: string } | null | undefined> };

export type FindOrCreateTagsMutationVariables = Exact<{
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type FindOrCreateTagsMutation = { __typename?: 'Mutation', findOrCreateTags: Array<{ __typename?: 'Tag', id: string, name: string } | null | undefined> };

export type IngredientUnitFieldsFragment = { __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined };

export type IngredientsListQueryVariables = Exact<{ [key: string]: never; }>;


export type IngredientsListQuery = { __typename?: 'Query', ingredients: Array<{ __typename?: 'Ingredient', id: string, name: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string } | null | undefined };

export type RecipeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecipeQuery = { __typename?: 'Query', recipe?: { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, updatedAt: any, notes?: string | null | undefined, source?: string | null | undefined, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, sections: Array<{ __typename?: 'RecipeSection', id: string, order: number, cookTimeMinutes: number, prepTimeMinutes: number, description?: string | null | undefined, name: string, servingUnit: string, servings: number, ingredients: Array<{ __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined }>, steps: Array<{ __typename?: 'Step', id: string, description: string, order: number }>, recipe: { __typename?: 'Recipe', id: string } }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> } | null | undefined };

export type RecipeCoreFieldsFragment = { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, notes?: string | null | undefined, source?: string | null | undefined, photo: Array<{ __typename?: 'File', id: string }> };

export type RecipePreviewFieldsFragment = { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> };

export type RecipeFieldsFragment = { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, updatedAt: any, notes?: string | null | undefined, source?: string | null | undefined, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, sections: Array<{ __typename?: 'RecipeSection', id: string, order: number, cookTimeMinutes: number, prepTimeMinutes: number, description?: string | null | undefined, name: string, servingUnit: string, servings: number, ingredients: Array<{ __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined }>, steps: Array<{ __typename?: 'Step', id: string, description: string, order: number }>, recipe: { __typename?: 'Recipe', id: string } }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> };

export type RecipeSectionFieldsFragment = { __typename?: 'RecipeSection', id: string, order: number, cookTimeMinutes: number, prepTimeMinutes: number, description?: string | null | undefined, name: string, servingUnit: string, servings: number, ingredients: Array<{ __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined }>, steps: Array<{ __typename?: 'Step', id: string, description: string, order: number }>, recipe: { __typename?: 'Recipe', id: string } };

export type RecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type RecipesQuery = { __typename?: 'Query', recipes: Array<{ __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> };

export type TagQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type TagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', id: string, name: string, recipes: Array<{ __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> } | null | undefined };

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, name: string, recipes: Array<{ __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> }> };

export type TagsListQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsListQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, name: string }> };

export type UnitSizeFieldsFragment = { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType };

export type UnitSizesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitSizesQuery = { __typename?: 'Query', unitSizes: Array<{ __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType }> };

export type UpdateOneIngredientUnitMutationVariables = Exact<{
  id: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type UpdateOneIngredientUnitMutation = { __typename?: 'Mutation', updateOneIngredientUnit?: { __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined } | null | undefined };

export type UpdateOneRecipeMutationVariables = Exact<{
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<TagUpdateManyWithoutRecipesInput>;
  sections?: Maybe<RecipeSectionUpdateManyWithoutRecipeInput>;
}>;


export type UpdateOneRecipeMutation = { __typename?: 'Mutation', updateOneRecipe?: { __typename?: 'Recipe', id: string, title: string, description?: string | null | undefined, createdAt: any, updatedAt: any, notes?: string | null | undefined, source?: string | null | undefined, author: { __typename?: 'User', id: string, name: string }, photo: Array<{ __typename?: 'File', id: string }>, sections: Array<{ __typename?: 'RecipeSection', id: string, order: number, cookTimeMinutes: number, prepTimeMinutes: number, description?: string | null | undefined, name: string, servingUnit: string, servings: number, ingredients: Array<{ __typename?: 'IngredientUnit', id: string, amount?: number | null | undefined, notes?: string | null | undefined, order: number, unit?: { __typename?: 'UnitSize', id: string, name: string, plural: string, unitType: UnitType } | null | undefined, ingredient?: { __typename?: 'Ingredient', id: string, name: string } | null | undefined }>, steps: Array<{ __typename?: 'Step', id: string, description: string, order: number }>, recipe: { __typename?: 'Recipe', id: string } }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> } | null | undefined };

export const RecipeCoreFieldsFragmentDoc = gql`
    fragment RecipeCoreFields on Recipe {
  id
  title
  description
  photo {
    id
  }
  notes
  source
}
    `;
export const RecipePreviewFieldsFragmentDoc = gql`
    fragment RecipePreviewFields on Recipe {
  id
  title
  author {
    id
    name
  }
  description
  photo {
    id
  }
  createdAt
  tags {
    id
    name
  }
}
    `;
export const UnitSizeFieldsFragmentDoc = gql`
    fragment UnitSizeFields on UnitSize {
  id
  name
  plural
  unitType
}
    `;
export const IngredientUnitFieldsFragmentDoc = gql`
    fragment IngredientUnitFields on IngredientUnit {
  id
  amount
  unit {
    ...UnitSizeFields
  }
  notes
  ingredient {
    id
    name
  }
  order
}
    ${UnitSizeFieldsFragmentDoc}`;
export const RecipeSectionFieldsFragmentDoc = gql`
    fragment RecipeSectionFields on RecipeSection {
  id
  order
  cookTimeMinutes
  prepTimeMinutes
  description
  ingredients(orderBy: {order: asc}) {
    ...IngredientUnitFields
  }
  name
  servingUnit
  servings
  steps(orderBy: {order: asc}) {
    id
    description
    order
  }
  recipe {
    id
  }
}
    ${IngredientUnitFieldsFragmentDoc}`;
export const RecipeFieldsFragmentDoc = gql`
    fragment RecipeFields on Recipe {
  id
  title
  author {
    id
    name
  }
  description
  photo {
    id
  }
  createdAt
  updatedAt
  notes
  sections(orderBy: {order: asc}) {
    ...RecipeSectionFields
  }
  source
  tags {
    id
    name
  }
}
    ${RecipeSectionFieldsFragmentDoc}`;
export const CreateOneIngredientUnitDocument = gql`
    mutation createOneIngredientUnit($order: Int, $notes: String, $ingredient: IngredientCreateNestedOneWithoutIngredientUnitInput, $amount: Float, $recipeSectionId: String!, $unit: UnitSizeCreateNestedOneWithoutIngredientUnitInput) {
  createOneIngredientUnit(
    data: {order: $order, notes: $notes, recipeSection: {connect: {id: $recipeSectionId}}, ingredient: $ingredient, amount: $amount, unit: $unit}
  ) {
    id
    order
    notes
    amount
    unit {
      id
      name
      plural
      unitType
    }
    ingredient {
      id
      name
    }
  }
}
    `;

export function useCreateOneIngredientUnitMutation() {
  return Urql.useMutation<CreateOneIngredientUnitMutation, CreateOneIngredientUnitMutationVariables>(CreateOneIngredientUnitDocument);
};
export const CreateOneRecipeDocument = gql`
    mutation createOneRecipe($title: String!, $author: UserCreateNestedOneWithoutRecipesInput!, $notes: String, $description: String, $source: String, $tags: TagCreateNestedManyWithoutRecipesInput, $sections: RecipeSectionCreateNestedManyWithoutRecipeInput) {
  createOneRecipe(
    data: {title: $title, author: $author, notes: $notes, description: $description, source: $source, tags: $tags, sections: $sections}
  ) {
    ...RecipePreviewFields
  }
}
    ${RecipePreviewFieldsFragmentDoc}`;

export function useCreateOneRecipeMutation() {
  return Urql.useMutation<CreateOneRecipeMutation, CreateOneRecipeMutationVariables>(CreateOneRecipeDocument);
};
export const CreateOneRecipeSectionDocument = gql`
    mutation createOneRecipeSection($name: String!, $description: String, $prepTimeMinutes: Int!, $cookTimeMinutes: Int!, $ingredients: IngredientUnitCreateNestedManyWithoutRecipeSectionInput, $servings: Int!, $servingUnit: String!, $steps: StepCreateNestedManyWithoutRecipeSectionInput, $recipe: RecipeCreateNestedOneWithoutSectionsInput!) {
  createOneRecipeSection(
    data: {name: $name, description: $description, prepTimeMinutes: $prepTimeMinutes, cookTimeMinutes: $cookTimeMinutes, ingredients: $ingredients, servings: $servings, servingUnit: $servingUnit, steps: $steps, recipe: $recipe}
  ) {
    ...RecipeSectionFields
  }
}
    ${RecipeSectionFieldsFragmentDoc}`;

export function useCreateOneRecipeSectionMutation() {
  return Urql.useMutation<CreateOneRecipeSectionMutation, CreateOneRecipeSectionMutationVariables>(CreateOneRecipeSectionDocument);
};
export const CreateOneStepDocument = gql`
    mutation createOneStep($order: Int, $description: String!, $recipeSection: RecipeSectionCreateNestedOneWithoutStepsInput!) {
  createOneStep(
    data: {order: $order, description: $description, recipeSection: $recipeSection}
  ) {
    id
    order
    description
  }
}
    `;

export function useCreateOneStepMutation() {
  return Urql.useMutation<CreateOneStepMutation, CreateOneStepMutationVariables>(CreateOneStepDocument);
};
export const DeleteOneIngredientUnitDocument = gql`
    mutation deleteOneIngredientUnit($id: String!) {
  deleteOneIngredientUnit(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteOneIngredientUnitMutation() {
  return Urql.useMutation<DeleteOneIngredientUnitMutation, DeleteOneIngredientUnitMutationVariables>(DeleteOneIngredientUnitDocument);
};
export const DeleteOneRecipeDocument = gql`
    mutation deleteOneRecipe($id: String!) {
  deleteOneRecipe(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteOneRecipeMutation() {
  return Urql.useMutation<DeleteOneRecipeMutation, DeleteOneRecipeMutationVariables>(DeleteOneRecipeDocument);
};
export const DeleteOneRecipeSectionDocument = gql`
    mutation deleteOneRecipeSection($id: String!) {
  deleteOneRecipeSection(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteOneRecipeSectionMutation() {
  return Urql.useMutation<DeleteOneRecipeSectionMutation, DeleteOneRecipeSectionMutationVariables>(DeleteOneRecipeSectionDocument);
};
export const DeleteOneStepDocument = gql`
    mutation deleteOneStep($id: String!) {
  deleteOneStep(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteOneStepMutation() {
  return Urql.useMutation<DeleteOneStepMutation, DeleteOneStepMutationVariables>(DeleteOneStepDocument);
};
export const FilterRecipesDocument = gql`
    query filterRecipes($searchString: String!) {
  filterRecipes(searchString: $searchString) {
    id
    title
    description
  }
}
    `;

export function useFilterRecipesQuery(options: Omit<Urql.UseQueryArgs<FilterRecipesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FilterRecipesQuery>({ query: FilterRecipesDocument, ...options });
};
export const FindOrCreateIngredientsDocument = gql`
    mutation findOrCreateIngredients($names: [String!]!) {
  findOrCreateIngredients(names: $names) {
    id
    name
  }
}
    `;

export function useFindOrCreateIngredientsMutation() {
  return Urql.useMutation<FindOrCreateIngredientsMutation, FindOrCreateIngredientsMutationVariables>(FindOrCreateIngredientsDocument);
};
export const FindOrCreateTagsDocument = gql`
    mutation findOrCreateTags($names: [String!]!) {
  findOrCreateTags(names: $names) {
    id
    name
  }
}
    `;

export function useFindOrCreateTagsMutation() {
  return Urql.useMutation<FindOrCreateTagsMutation, FindOrCreateTagsMutationVariables>(FindOrCreateTagsDocument);
};
export const IngredientsListDocument = gql`
    query ingredientsList {
  ingredients {
    id
    name
  }
}
    `;

export function useIngredientsListQuery(options: Omit<Urql.UseQueryArgs<IngredientsListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<IngredientsListQuery>({ query: IngredientsListDocument, ...options });
};
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;

export function useMeQuery(options: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MeQuery>({ query: MeDocument, ...options });
};
export const RecipeDocument = gql`
    query recipe($id: String!) {
  recipe(where: {id: $id}) {
    ...RecipeFields
  }
}
    ${RecipeFieldsFragmentDoc}`;

export function useRecipeQuery(options: Omit<Urql.UseQueryArgs<RecipeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<RecipeQuery>({ query: RecipeDocument, ...options });
};
export const RecipesDocument = gql`
    query recipes {
  recipes(orderBy: {createdAt: desc}) {
    ...RecipePreviewFields
  }
}
    ${RecipePreviewFieldsFragmentDoc}`;

export function useRecipesQuery(options: Omit<Urql.UseQueryArgs<RecipesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<RecipesQuery>({ query: RecipesDocument, ...options });
};
export const TagDocument = gql`
    query tag($name: String!) {
  tag(where: {name: $name}) {
    id
    name
    recipes {
      ...RecipePreviewFields
    }
  }
}
    ${RecipePreviewFieldsFragmentDoc}`;

export function useTagQuery(options: Omit<Urql.UseQueryArgs<TagQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TagQuery>({ query: TagDocument, ...options });
};
export const TagsDocument = gql`
    query tags {
  tags {
    id
    name
    recipes {
      ...RecipePreviewFields
    }
  }
}
    ${RecipePreviewFieldsFragmentDoc}`;

export function useTagsQuery(options: Omit<Urql.UseQueryArgs<TagsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TagsQuery>({ query: TagsDocument, ...options });
};
export const TagsListDocument = gql`
    query tagsList {
  tags {
    id
    name
  }
}
    `;

export function useTagsListQuery(options: Omit<Urql.UseQueryArgs<TagsListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TagsListQuery>({ query: TagsListDocument, ...options });
};
export const UnitSizesDocument = gql`
    query unitSizes {
  unitSizes {
    ...UnitSizeFields
  }
}
    ${UnitSizeFieldsFragmentDoc}`;

export function useUnitSizesQuery(options: Omit<Urql.UseQueryArgs<UnitSizesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UnitSizesQuery>({ query: UnitSizesDocument, ...options });
};
export const UpdateOneIngredientUnitDocument = gql`
    mutation updateOneIngredientUnit($id: String!, $amount: Float, $notes: String, $ingredientId: String, $unitId: String, $order: Int) {
  updateOneIngredientUnit(
    data: {amount: $amount, notes: $notes, ingredient: {connect: {id: $ingredientId}}, unit: {connect: {id: $unitId}}, order: $order}
    where: {id: $id}
  ) {
    ...IngredientUnitFields
  }
}
    ${IngredientUnitFieldsFragmentDoc}`;

export function useUpdateOneIngredientUnitMutation() {
  return Urql.useMutation<UpdateOneIngredientUnitMutation, UpdateOneIngredientUnitMutationVariables>(UpdateOneIngredientUnitDocument);
};
export const UpdateOneRecipeDocument = gql`
    mutation updateOneRecipe($id: String!, $title: String, $description: String, $notes: String, $source: String, $tags: TagUpdateManyWithoutRecipesInput, $sections: RecipeSectionUpdateManyWithoutRecipeInput) {
  updateOneRecipe(
    data: {title: $title, description: $description, notes: $notes, source: $source, tags: $tags, sections: $sections}
    where: {id: $id}
  ) {
    ...RecipeFields
  }
}
    ${RecipeFieldsFragmentDoc}`;

export function useUpdateOneRecipeMutation() {
  return Urql.useMutation<UpdateOneRecipeMutation, UpdateOneRecipeMutationVariables>(UpdateOneRecipeDocument);
};