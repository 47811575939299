import TextField from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, Path, PathValue, UnpackNestedValue } from 'react-hook-form';

interface Props<T, TFieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  defaultValue: T | T[];
  children: React.ReactNode;
  required: boolean;
}

export default function ControlledSelect<T, TFieldValues>({
  control,
  name,
  defaultValue,
  children,
  required,
  label,
}: Props<T, TFieldValues>): JSX.Element {
  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          select={true}
          required={required}
          // eslint-disable-next-line react/prop-types
          onChange={(ev) => field.onChange(ev.target.value)}
        >
          {children}
        </TextField>
      )}
      defaultValue={defaultValue as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>}
      name={name}
      control={control}
    />
  );
}
