import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useCreateOneRecipeMutation, useRecipesQuery } from '../generated/graphql';
import { useAuth0 } from '../react-auth0-spa';
import LowerRightFab from './LowerRightFab';
import RecipePreviewCardGrid from './RecipePreviewCardGrid';
import { useNavigate } from 'react-router';

const Home: React.FunctionComponent = () => {
  const { user } = useAuth0();
  const [{ error, data }] = useRecipesQuery();
  const [, createOneRecipe] = useCreateOneRecipeMutation();
  const navigate = useNavigate();

  const addRecipe = async (): Promise<void> => {
    const recipeData = await createOneRecipe({
      author: {
        connect: {
          email: user?.email,
        },
      },
      title: `New Recipe ${+new Date()}`,
    });

    navigate(`/recipe/${recipeData.data?.createOneRecipe.id}/edit`);
  };

  if (error || !data) return <p>Error :( {JSON.stringify(error)}</p>;

  return (
    <>
      <Typography variant="h4" gutterBottom={true} color="textPrimary">
        Latest Recipes
      </Typography>
      <RecipePreviewCardGrid recipes={data?.recipes} />
      {user?.hasAuthorization('create:all') && (
        <LowerRightFab color="primary" aria-label="add" onClick={(): Promise<void> => addRecipe()}>
          <AddIcon />
        </LowerRightFab>
      )}
    </>
  );
};

export default Home;
