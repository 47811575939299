import { blue, grey, pink } from '@mui/material/colors';
import { createTheme, darken } from '@mui/material/styles';

// A custom theme for this app
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: blue[200],
    },
    secondary: {
      main: pink[200],
    },
    // type: paletteType,
    background: {
      default: grey[900],
      paper: '#333',
    },
    mode: 'dark',
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: darken(pink.A400, 0.1),
    },
    // type: paletteType,
    background: {
      default: grey[100],
      paper: '#fff',
    },
    mode: 'light',
  },
});
