import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { useRecipesQuery } from '../generated/graphql';
import { useNavigate } from 'react-router-dom';

const SearchBar: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [{ fetching, /* error, */ data }] = useRecipesQuery();

  // if (error) {
  //   window.alert('An error occurred during the search :(');
  // }

  const [clickTo, setClickTo] = React.useState('');

  useEffect(() => {
    if (clickTo !== '') {
      navigate(clickTo);
      setClickTo('');
    }
  }, [clickTo]);

  const options = data?.recipes ? data.recipes : [];

  const filterOptions = createFilterOptions<typeof options[0]>({
    // matchFrom: 'start',
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option) => {
      return JSON.stringify(Object.values(option)).toLocaleLowerCase();
    },
  });

  return (
    <Autocomplete
      style={{ width: 250 }}
      loading={fetching}
      filterOptions={filterOptions}
      getOptionLabel={(option): string => {
        return option.title ?? '';
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          name="recipe-search-bar"
          sx={{
            margin: 1,
            marginBottom: 2,
            width: '100%',
          }}
          label="Search"
        />
      )}
      renderOption={(props, option): JSX.Element => {
        return (
          <li
            {...props}
            onClick={(): void => {
              setClickTo(`/recipe/${option.id}`);
            }}
          >
            <Typography variant="subtitle1">
              <strong>{option.title}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              :{' '}
              {option.description?.length && option.description.length > 100
                ? `${option.description?.substring(0, 100)}...`
                : option.description}
            </Typography>
          </li>
        );
      }}
    />
  );
};

export default SearchBar;
