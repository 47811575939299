import Fab from '@mui/material/Fab';
import { experimentalStyled as styled } from '@mui/material/styles';

const LowerRightFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export default LowerRightFab;
