import { cacheExchange } from '@urql/exchange-graphcache';
import assert from 'assert';
import PropTypes from 'prop-types';
import React from 'react';
import { createClient, dedupExchange, Exchange, fetchExchange, Operation, Provider } from 'urql';
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka';
import ENV from '../env';
import schema from '../generated/graphql.json';
import optimistic from '../queries/optimistic';
import updates from '../queries/updates';
import { useAuth0 } from '../react-auth0-spa';

function isPromise<T>(param: T | Promise<T>): param is Promise<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof param === 'object' && typeof (param as any).then === 'function';
}

type FnType = (param: RequestInit | (() => RequestInit) | undefined) => RequestInit | Promise<RequestInit>;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchOptionsExchange =
  (fn: FnType): Exchange =>
  ({ forward }) =>
  (ops$) => {
    return pipe(
      ops$,
      mergeMap((operation: Operation) => {
        const result = fn(operation.context.fetchOptions);
        return pipe(
          isPromise(result) ? fromPromise<RequestInit>(result) : fromValue<RequestInit>(result),
          map((fetchOptions: RequestInit | (() => RequestInit)) => ({
            ...operation,
            context: { ...operation.context, fetchOptions },
          })),
        );
      }),
      forward,
    );
  };

const AuthorizedUrqlProvider: React.FunctionComponent = ({ children }) => {
  const { getTokenSilently, isAuthenticated } = useAuth0();
  assert(ENV.REACT_APP_BACKEND_URL, 'ENV.REACT_APP_BACKEND_URL is not defined');
  const client = createClient({
    url: `${ENV.REACT_APP_BACKEND_URL}/graphql`,
    suspense: true,
    exchanges: [
      dedupExchange,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cacheExchange({ schema: schema as any, optimistic, updates }),
      fetchOptionsExchange(async (fetchOptions?: RequestInit | (() => RequestInit)) => {
        const options: { headers?: HeadersInit } = {};
        if (isAuthenticated) {
          const token = await getTokenSilently();
          options.headers = { authorization: token ? `Bearer ${token}` : '' };
        }

        return Promise.resolve({
          ...fetchOptions,
          ...options,
        });
      }),
      fetchExchange,
    ],
  });

  return <Provider value={client}>{children}</Provider>;
};
AuthorizedUrqlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthorizedUrqlProvider;
