import { Cache, Data, UpdatesConfig, Variables } from '@urql/exchange-graphcache';
import {
  FindOrCreateIngredientsMutation,
  FindOrCreateTagsMutation,
  IngredientsListDocument,
  IngredientsListQuery,
  Recipe,
  RecipesDocument,
  RecipesQuery,
  TagsListDocument,
  TagsListQuery,
} from '../generated/graphql';

export const updates: UpdatesConfig = {
  Mutation: {
    createOneRecipe: (result: Data, args: Variables, cache: Cache): void => {
      cache.updateQuery<RecipesQuery>({ query: RecipesDocument }, (data) => {
        if (!data?.recipes) {
          return null;
        }
        const recipes = [result.createOneRecipe as Recipe, ...data.recipes];
        data.recipes = recipes;
        return data;
      });
    },
    deleteOneRecipe: (result: Data, args: Variables, cache: Cache): void => {
      cache.updateQuery<RecipesQuery>({ query: RecipesDocument }, (data) => {
        if (!data?.recipes) {
          return null;
        }
        const recipes = [...data.recipes];
        recipes.filter((recipe) => recipe.id !== args.id);
        data.recipes = recipes;
        return data;
      });
    },
    findOrCreateIngredients: (result: Data, args: Variables, cache: Cache): void => {
      cache.updateQuery<IngredientsListQuery>({ query: IngredientsListDocument }, (data) => {
        const useResult = result as FindOrCreateIngredientsMutation;
        if (!data?.ingredients || !useResult.findOrCreateIngredients?.[0]) {
          return null;
        }

        if (!data.ingredients.find((t) => t.name === useResult?.findOrCreateIngredients?.[0]?.name)) {
          const ingredients = [...data.ingredients];
          ingredients.push(useResult.findOrCreateIngredients[0]);
          data.ingredients = ingredients;
        }
        return data;
      });
    },
    findOrCreateTags: (result: Data, args: Variables, cache: Cache): void => {
      cache.updateQuery<TagsListQuery>({ query: TagsListDocument }, (data) => {
        const useResult = result as FindOrCreateTagsMutation;
        if (!data?.tags || !useResult.findOrCreateTags?.[0]?.name) {
          return null;
        }

        if (!data.tags.find((t) => t.name === useResult?.findOrCreateTags?.[0]?.name)) {
          const tags = [...data.tags];
          tags.push(useResult.findOrCreateTags[0]);
          data.tags = tags;
        }
        return data;
      });
    },
  },
  Subscription: {},
};

export default updates;
