import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreIcon from '@mui/icons-material/MoreVert';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';
import LinkComponent from './LinkComponent';
import SearchBar from './SearchBar';

const NavBar: React.FunctionComponent = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (): void => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} to="/profile" component={LinkComponent}>
        Profile
      </MenuItem>
      <MenuItem
        onClick={(): void => {
          logout();
          handleMenuClose();
        }}
      >
        Log out
      </MenuItem>
    </Menu>
  );

  const AccountIcon = (): JSX.Element =>
    user?.picture ? (
      <Avatar src={user.picture} sx={{ width: '24px', height: '24px' }}>
        {user?.name?.[0] || ':)'}
      </Avatar>
    ) : (
      <AccountCircle />
    );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*       <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem to="/profile" component={LinkComponent}>
        <AccountIcon />
        <p>Profile</p>
      </MenuItem>
      <MenuItem
        onClick={(): void => {
          logout();
        }}
      >
        <IconButton
          aria-label="logout current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : undefined),
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.paper : undefined),
          transition: (theme) => theme.transitions.create('width'),
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              display: {
                sm: 'block',
                xs: 'none',
              },
            }}
            noWrap={true}
          >
            <Link
              component={RouterLink}
              to="/"
              sx={{
                color: (theme) => `${theme.palette.text.primary} !important`,
                textDecoration: 'none',
              }}
            >
              Cookbook
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', marginRight: 4 }}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <SearchBar />
            </React.Suspense>
          </Box>
          {!isAuthenticated && (
            <Button variant="contained" color="secondary" onClick={(): Promise<void> => loginWithRedirect({})}>
              Log in
            </Button>
          )}

          {isAuthenticated && (
            <>
              <Box
                sx={{
                  display: {
                    md: 'flex',
                    xs: 'none',
                  },
                }}
              >
                {/*                 <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton> */}
                {/* <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton> */}
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: {
                    md: 'none',
                    xs: 'flex',
                  },
                }}
              >
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
    </>
  );
};

export default NavBar;
