import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  IdToken,
  LogoutOptions,
  User,
} from '@auth0/auth0-spa-js/dist/typings/global';
import React, { Context, useContext, useEffect, useState } from 'react';
import ENV from './env';

export interface Auth0User extends User {
  hasAuthorization: (permission: string) => boolean;
  sub?: string;
}

interface AugmentedToken extends IdToken {
  'http://permission.namespace/': { permissions: string[] };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_REDIRECT_CALLBACK = (appState?: unknown) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext({});

const augmentUserPermissions = async (user: User, auth0Client: Auth0Client) => {
  const claims = await auth0Client.getIdTokenClaims();
  user.hasAuthorization = (permission: string) =>
    (claims as AugmentedToken)['http://permission.namespace/']?.permissions.includes(permission); // hack for type deficiency
  return user as Auth0User;
};

interface Auth0ContextProvider {
  isInitializing: boolean;
  user: Auth0User;
  isAuthenticated: boolean;
  loginWithRedirect: typeof Auth0Client.prototype.loginWithRedirect;
  logout: typeof Auth0Client.prototype.logout;
  getTokenSilently: typeof Auth0Client.prototype.getTokenSilently;
  popupOpen: boolean;
  loginWithPopup: typeof Auth0Client.prototype.loginWithPopup;
  handleRedirectCallback: typeof Auth0Client.prototype.handleRedirectCallback;
  getIdTokenClaims: typeof Auth0Client.prototype.getIdTokenClaims;
  getTokenWithPopup: typeof Auth0Client.prototype.getTokenWithPopup;
}

export const useAuth0 = (): Auth0ContextProvider =>
  useContext<Auth0ContextProvider>(Auth0Context as unknown as Context<Auth0ContextProvider>);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ClientOptions & {
  children: JSX.Element | JSX.Element[];
  onRedirectCallback: typeof DEFAULT_REDIRECT_CALLBACK;
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<Auth0User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [isInitializing, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        if (user) {
          setUser(await augmentUserPermissions(user, auth0FromHook));
        }
      }

      // apolloClient.resetStore();
      setLoading(false);
    };

    const initDebug = async () => {
      const developmentUser = JSON.parse(ENV.REACT_APP_DEV_USER || '{}');
      setLoading(false);
      setIsAuthenticated(true);
      setUser({ ...developmentUser, hasAuthorization: () => true });
    };

    if (process.env.NODE_ENV === 'development' && ENV.REACT_APP_DEV_USER) {
      initDebug();
    } else {
      initAuth0();
    }

    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    if (user && auth0Client) {
      setUser(await augmentUserPermissions(user, auth0Client));
    }
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    if (user && auth0Client) {
      setUser(await augmentUserPermissions(user, auth0Client));
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isInitializing,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: GetIdTokenClaimsOptions[]) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p: GetIdTokenClaimsOptions[]) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p: GetIdTokenClaimsOptions[]) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p: GetIdTokenClaimsOptions[]) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p: LogoutOptions[]) => {
          return auth0Client?.logout(...p);
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
