import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import {
  RecipeFieldsFragment,
  useCreateOneRecipeSectionMutation,
  useDeleteOneRecipeSectionMutation,
} from '../../generated/graphql';
import EditIngredients from './EditIngredients';
import EditSteps from './EditSteps';

interface NestedFieldProps<TFieldValues> {
  recipeId: string;
  control: Control<TFieldValues>;
  register: UseFormRegister<TFieldValues>;
}

export const EditSections = ({ recipeId, control, register }: NestedFieldProps<RecipeFieldsFragment>): JSX.Element => {
  const [, createOneRecipeSection] = useCreateOneRecipeSectionMutation();
  const [, deleteOneRecipeSection] = useDeleteOneRecipeSectionMutation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `sections`,
    keyName: 'fieldId',
  });

  const addSection = async (): Promise<void> => {
    const updateResult = await createOneRecipeSection(
      {
        description: '',
        cookTimeMinutes: 0,
        name: '',
        prepTimeMinutes: 0,
        servingUnit: 'servings',
        servings: 1,
        ingredients: undefined,
        steps: undefined,
        recipe: {
          connect: {
            id: recipeId,
          },
        },
      },
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   createOneStep: {
      //     ...section!,
      //     steps: [
      //       ...section!.steps,
      //       {
      //         __typename: 'Step',
      //         id: '' + Math.random(),
      //         description: '',
      //         order,
      //       },
      //     ],
      //   },
      // },
    );

    if (updateResult.error) {
      alert(JSON.stringify(updateResult.error));
      return;
    }

    append(updateResult.data?.createOneRecipeSection || {});
  };

  const removeSection = async (id: string, index: number): Promise<void> => {
    const deleteResult = await deleteOneRecipeSection({
      id,
    });

    if (deleteResult.error) {
      alert(JSON.stringify(deleteResult.error));
      return;
    }

    remove(index);
  };

  return (
    <>
      {fields.map((item, sectionIndex) => {
        const { ref: snameRef, ...snameRest } = register(`sections.${sectionIndex}.name` as 'sections.0.name', {
          required: true,
        });
        const { ref: prepTimeMinutesRef, ...prepTimeMinutesRest } = register(
          `sections.${sectionIndex}.prepTimeMinutes` as 'sections.0.prepTimeMinutes',
          {
            required: true,
            valueAsNumber: true,
          },
        );
        const { ref: cookTimeMinutesRef, ...cookTimeMinutesRest } = register(
          `sections.${sectionIndex}.cookTimeMinutes` as 'sections.0.cookTimeMinutes',
          {
            required: true,
            valueAsNumber: true,
          },
        );
        const { ref: servingsRef, ...servingsRest } = register(
          `sections.${sectionIndex}.servings` as 'sections.0.servings',
          {
            required: true,
            valueAsNumber: true,
          },
        );
        const { ref: servingUnitRef, ...servingUnitRest } = register(
          `sections.${sectionIndex}.servingUnit` as 'sections.0.servingUnit',
          {
            required: true,
          },
        );
        return (
          <Box
            key={item.fieldId}
            sx={{
              marginLeft: 2,
            }}
          >
            <input
              type="hidden"
              {...register(`sections.${sectionIndex}.id` as 'sections.0.id', { required: true })}
              defaultValue={item.id}
            />
            <input
              type="hidden"
              {...register(`sections.${sectionIndex}.order` as 'sections.0.order', { required: true })}
              defaultValue={item.order}
            />
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <IconButton aria-label="remove" onClick={(): Promise<void> => removeSection(item.id, sectionIndex)}>
                  <RemoveCircleOutlineIcon color="secondary" fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    marginTop: 2,
                  }}
                >
                  {`Section ${sectionIndex + 1}`}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <TextField
                fullWidth={true}
                label="Section name"
                inputRef={snameRef}
                {...snameRest}
                defaultValue={item.name}
              />
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <TextField
                    label="Prep time"
                    type="number"
                    inputRef={prepTimeMinutesRef}
                    {...prepTimeMinutesRest}
                    defaultValue={item.prepTimeMinutes}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Cook time"
                    type="number"
                    inputRef={cookTimeMinutesRef}
                    {...cookTimeMinutesRest}
                    defaultValue={item.cookTimeMinutes}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Servings"
                    type="number"
                    inputRef={servingsRef}
                    {...servingsRest}
                    defaultValue={item.servings}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Serving Unit"
                    inputRef={servingUnitRef}
                    {...servingUnitRest}
                    defaultValue={item.servingUnit}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  marginTop: 2,
                }}
              >
                Ingredients
              </Typography>
              <React.Suspense fallback={<div>Loading...</div>}>
                <EditIngredients
                  control={control}
                  sectionIndex={sectionIndex}
                  register={register}
                  sectionId={item.id}
                />
              </React.Suspense>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  marginTop: 2,
                }}
              >
                Steps
              </Typography>
              <EditSteps control={control} sectionIndex={sectionIndex} register={register} sectionId={item.id} />
            </Box>
          </Box>
        );
      })}
      <Button color="secondary" type="button" onClick={(): Promise<void> => addSection()}>
        Add a section
      </Button>
    </>
  );
};

export default EditSections;
