import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { BreadCrumbContext } from '../context/BreadCrumbContext';
import LinkComponent from './LinkComponent';

export const BreadCrumbs: React.FunctionComponent = () => {
  const [levels] = React.useContext(BreadCrumbContext);
  const useLevels = [...levels];
  const lastLevel = useLevels.pop();

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: 1, marginBottom: 1 }}>
      <Link color="inherit" href="/" to={'/'} component={LinkComponent}>
        Home
      </Link>
      {(useLevels || []).map((level, index) => (
        <Link key={index} color="inherit" href={level.location} to={level.location} component={LinkComponent}>
          {level.name ?? '...'}
        </Link>
      ))}
      {lastLevel && <Typography color="textPrimary">{lastLevel.name}</Typography>}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
