/* eslint-disable react/prop-types */
import { Chip, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbContext } from '../context/BreadCrumbContext';
import ENV from '../env';
import { useDeleteOneRecipeMutation, useMeQuery, useRecipeQuery } from '../generated/graphql';
import { useAuth0 } from '../react-auth0-spa';
import RecipeSection from './RecipeSection';

export const Recipe: React.FunctionComponent = () => {
  const { user } = useAuth0();
  const params = useParams();
  const navigate = useNavigate();

  if (!params.id) {
    throw new Error(`Missing recipe ID`);
  }

  const [{ data: meData }] = useMeQuery();
  const [{ error, data }] = useRecipeQuery({
    variables: {
      id: params.id,
    },
  });

  const [, deleteOneRecipe] = useDeleteOneRecipeMutation();

  const [, setLevels] = useContext(BreadCrumbContext);

  useEffect(() => {
    const newLevels = [];
    if (!data?.recipe) {
      return;
    }

    const tags = data.recipe.tags;
    tags.forEach((tag) =>
      newLevels.push({
        name: tag.name,
        location: `/tag/${tag.name}`,
      }),
    );
    newLevels.push({
      name: data.recipe.title,
      location: `/recipe/${data.recipe.id}`,
    });

    setLevels(newLevels);

    return (): void => setLevels([]);
  }, [data]);

  // if (fetching) return <p>Loading...</p>;
  if (error || !data) return <p>Error :( {JSON.stringify(error)}</p>;

  const deleteRecipe = async (): Promise<void> => {
    if (!params.id) {
      return;
    }
    await deleteOneRecipe({ id: params.id });
    navigate('/');
  };

  const createdDT = DateTime.fromISO(data?.recipe?.createdAt);
  const updatedDT = DateTime.fromISO(data?.recipe?.updatedAt);

  const showUpdated = createdDT.diff(updatedDT).months >= 1;

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              sx={{
                backgroundColor: 'red[500]',
              }}
            >
              {data.recipe?.author?.name[0]}
            </Avatar>
          }
          action={
            <>
              {(user?.hasAuthorization('delete:all') ||
                (user?.hasAuthorization('delete:own') && data.recipe?.author?.id === meData?.me?.id)) && (
                <IconButton aria-label="remove" onClick={deleteRecipe}>
                  <DeleteForeverIcon />
                </IconButton>
              )}
              {(user?.hasAuthorization('edit:all') ||
                (user?.hasAuthorization('edit:own') && data.recipe?.author?.id === meData?.me?.id)) && (
                <IconButton aria-label="edit" onClick={(): void => navigate(`/recipe/${params.id}/edit`)}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          }
          title={data.recipe?.title}
          subheader={`Added ${createdDT.toLocaleString({
            month: 'long',
            year: 'numeric',
          })}${showUpdated ? ` (updated ${updatedDT.diff(createdDT).months} months later)` : ''}`}
        />
        {(data.recipe?.photo?.length || 0) > 0 && (
          <CardMedia
            sx={{
              height: 0,
              paddingTop: '56.25%', // 16:9
            }}
            image={`${ENV.REACT_APP_BACKEND_URL}/file/${data.recipe?.photo?.[0]?.id}`}
            title={data.recipe?.title}
          />
        )}
        <CardContent>
          {data.recipe?.tags?.map((tag) => (
            <Chip
              key={tag.name}
              sx={{
                marginRight: 1,
              }}
              label={tag.name}
              onClick={(ev): void => {
                ev.preventDefault();
                navigate(`/tag/${tag.name}`);
              }}
            />
          ))}
          <Typography variant="body2" color="textSecondary" component="p">
            {data.recipe?.description}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <i>{data.recipe?.notes}</i>
          </Typography>
          {data.recipe?.sections?.map((section, index, list) => (
            <RecipeSection key={index} section={section} hideSectionTitles={list.length === 1} />
          ))}
          {data.recipe?.source && (
            <Typography variant="body2" color="textSecondary" component="p">
              Source:{' '}
              <MUILink href={data.recipe?.source} target="_blank">
                {data.recipe?.source}
              </MUILink>
            </Typography>
          )}
          {data.recipe?.updatedAt && (
            <Typography variant="body2" color="textSecondary" component="p">
              Last updated: {DateTime.fromISO(data.recipe?.updatedAt).toLocaleString()}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Recipe;
