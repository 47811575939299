/* eslint-disable react/prop-types */
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';
import { RecipeQuery, UnitSizesQuery, UnitType, useUnitSizesQuery } from '../generated/graphql';
import { normalizeAmount } from '../utils/equivalents';

const formatAmount = (amount: number): string | number => {
  const first = Math.floor(amount);
  const remainder = amount % 1;
  const whole = first === 0 ? '' : `${first} `;

  if (remainder === 1 / 8) {
    return `${whole}⅛`;
  } else if (remainder === 1 / 4) {
    return `${whole}¼`;
  } else if (remainder === 1 / 2) {
    return `${whole}½`;
  } else if (remainder === 3 / 4) {
    return `${whole}¾`;
  } else if (remainder > 1 / 3 && remainder < 1 / 2) {
    return `${whole}⅓`;
  } else if (remainder > 2 / 3 && remainder < 3 / 4) {
    return `${whole}⅔`;
  } else {
    return amount;
  }
};

const makeIngredientText = (
  ingredient: NonNullable<RecipeQuery['recipe']>['sections'][0]['ingredients'][0],
  multiplicationFactor?: number,
  unitSizes?: UnitSizesQuery,
): string => {
  if (!ingredient.ingredient) {
    return '';
  }

  const ingredientName = ingredient.ingredient.name;

  if (!ingredient.amount) {
    return ingredientName;
  }

  const {
    amount: rawAmount,
    unitName,
    extra,
  } = normalizeAmount(ingredient.amount * (multiplicationFactor || 1), ingredient.unit?.name);

  // XXX handle thirds eventually (cups only)
  const amount = rawAmount - (extra?.remainder || 0);

  const formattedAmount = `${extra?.amount ? formatAmount(extra.amount) + ` ${extra.unitName}, ` : ''}${formatAmount(
    amount,
  )}`;

  // singular
  if (amount <= 1) {
    if (unitName?.trim()) {
      return `${formattedAmount}${
        ingredient.unit?.unitType === UnitType.Imperial ? ' ' : ''
      }${unitName} ${pluralize.singular(ingredientName)}`;
    } else {
      return `${formattedAmount} ${pluralize.singular(ingredientName)}`;
    }
    // plural
  } else {
    if (unitName?.trim()) {
      let pluralName = ingredient.unit?.name === unitName ? ingredient.unit?.plural : unitName;
      if (unitName !== ingredient.unit?.name && unitSizes) {
        pluralName = unitSizes.unitSizes.find((us) => us.name === unitName)?.plural || 'Error';
      }
      return `${formattedAmount}${
        ingredient.unit?.unitType === UnitType.Imperial ? ' ' : ''
      }${pluralName} ${pluralize.singular(ingredientName)}`;
    } else {
      return `${formattedAmount} ${pluralize.plural(ingredientName)}`;
    }
  }
};

interface Props {
  ingredients: NonNullable<RecipeQuery['recipe']>['sections'][0]['ingredients'];
  servingMultiplier?: number;
}

export const IngredientList: React.FunctionComponent<Props> = (props) => {
  const [checked, setChecked] = React.useState<string[]>([]);

  const [{ data: unitData }] = useUnitSizesQuery();

  const handleToggle = (value: string) => (): void => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List
      sx={{
        width: '100%',
        // maxWidth: 360,
        backgroundColor: 'palette.background.paper',
      }}
    >
      {props.ingredients.map((value): JSX.Element => {
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': value.id }}
              />
            </ListItemIcon>
            <ListItemText
              id={value.id}
              primary={makeIngredientText(value, props.servingMultiplier, unitData)}
              secondary={value.notes}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default IngredientList;
