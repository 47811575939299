import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { RecipePreviewFieldsFragment } from '../generated/graphql';
import RecipePreviewCard from './RecipePreviewCard';

interface RecipePreviewCardGridProps {
  recipes?: RecipePreviewFieldsFragment[];
}

const RecipePreviewCardGrid: React.FunctionComponent<RecipePreviewCardGridProps> = (props) => {
  if (!props.recipes) {
    return <></>;
  }

  return (
    <Grid container spacing={3}>
      {props.recipes?.map((recipe, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <RecipePreviewCard {...recipe} />
        </Grid>
      ))}
    </Grid>
  );
};
RecipePreviewCardGrid.propTypes = {
  recipes: PropTypes.array,
};

export default RecipePreviewCardGrid;
