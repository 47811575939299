import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import React, { useContext, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { BreadCrumbContext } from '../../context/BreadCrumbContext';
import {
  RecipeFieldsFragment,
  Tag,
  useFindOrCreateTagsMutation,
  useRecipeQuery,
  useTagsListQuery,
  useUpdateOneRecipeMutation,
} from '../../generated/graphql';
import ControlledAutoComplete, { isArrayType } from '../ControlledAutoComplete';
import LowerRightFab from '../LowerRightFab';
import EditSections from './EditSections';
import ENV from '../../env';
import { useAuth0 } from '../../react-auth0-spa';

export const EditRecipe: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useAuth0();

  if (!params.id) {
    throw new Error(`Missing recipe ID`);
  }

  const recipeId = params.id;

  const [{ error, data }] = useRecipeQuery({
    variables: {
      id: recipeId,
    },
  });

  const [{ data: tagsData }] = useTagsListQuery();

  const [, updateOneRecipe] = useUpdateOneRecipeMutation();
  const [, findOrCreateTags] = useFindOrCreateTagsMutation();

  const setTags = async (tags: string[]) => {
    if (!tags) {
      throw new Error('Missing tags');
    }

    return findOrCreateTags({
      names: tags,
    });
  };

  const onError: SubmitErrorHandler<RecipeFieldsFragment> = (errors, e) => console.error(errors, e);

  const onSubmit: SubmitHandler<RecipeFieldsFragment & { photoUpload?: FileList }> = async (values) => {
    console.log(values);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (values.photoUpload?.[0]) {
      const photoVal = values.photoUpload;
      const file = photoVal[0];
      const formData = new FormData();
      formData.append('photo', file);

      const token = await user.getTokenSilently();
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      await fetch(`${ENV.REACT_APP_BACKEND_URL}/recipe/${recipeId}/file`, {
        method: 'post',
        // credentials: '',
        headers,
        body: formData,
      });
    }

    await updateOneRecipe({
      id: recipeId,
      title: values.title,
      description: values.description,
      notes: values.notes,
      source: values.source,
      tags: {
        set: values.tags.map((tag) => ({ name: tag.name })),
      },
      sections: {
        update: values.sections.map((section) => ({
          where: { id: section.id },
          data: {
            name: section.name,
            cookTimeMinutes: +section.cookTimeMinutes || 0,
            prepTimeMinutes: +section.prepTimeMinutes || 0,
            servingUnit: section.servingUnit || 'servings',
            servings: +section.servings,
            description: section.description,
            ingredients: {
              update: section.ingredients.map((ing, index) => ({
                where: {
                  id: ing.id,
                },
                data: {
                  amount: ing.amount ? +ing.amount : undefined,
                  order: +index,
                  notes: ing.notes,
                  unit: ing.unit?.id
                    ? {
                        connect: { id: ing.unit.id },
                      }
                    : undefined,
                  ingredient: ing.ingredient
                    ? {
                        connect: { name: ing.ingredient.name },
                      }
                    : undefined,
                },
              })),
            },
            steps: {
              update: section.steps.map((step, index) => ({
                where: {
                  id: step.id,
                },
                data: {
                  order: +index,
                  description: step.description,
                },
              })),
            },
          },
        })),
      },
    });

    navigate(`/recipe/${recipeId}`);
  };

  const [, setLevels] = useContext(BreadCrumbContext);

  const { register, handleSubmit, control } = useForm<RecipeFieldsFragment & { photoUpload?: FileList }>({
    defaultValues: data?.recipe || undefined,
  });

  useEffect(() => {
    const newLevels = [];
    const tags = data?.recipe?.tags;
    tags?.forEach((tag) =>
      newLevels.push({
        name: tag.name,
        location: `/tag/${tag.name}`,
      }),
    );
    data?.recipe?.title &&
      newLevels.push({
        name: data?.recipe?.title,
        location: `/recipe/${recipeId}`,
      });

    newLevels.push({
      name: `Edit`,
      location: `/recipe/${recipeId}/edit`,
    });

    setLevels(newLevels);

    return (): void => setLevels([]);
  }, []);

  if (error || !data) {
    return <p>Error :( {JSON.stringify(error)}</p>;
  }

  // const handleFileDrop = (files: File[]) => {
  //   return;
  // };

  if (!tagsData?.tags) {
    return <div>Loading...</div>;
  }

  const { ref: titleRef, ...titleRest } = register('title', { required: true });
  const { ref: descRef, ...descRest } = register('description');
  const { ref: sourceRef, ...sourceRest } = register('source');
  const { ref: notesRef, ...notesRest } = register('notes');
  const { ref: photoRef, ...photoRest } = register('photoUpload');

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
      <Typography variant="h5" color="textSecondary">
        Details
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <div>
          <TextField fullWidth={true} label="Recipe title" required={true} inputRef={titleRef} {...titleRest} />
        </div>
        <div>
          <TextField fullWidth={true} label="Description" multiline={true} inputRef={descRef} {...descRest} />
        </div>
        <div>
          <TextField fullWidth={true} label="Source or inspiration" inputRef={sourceRef} {...sourceRest} />
        </div>
        <div>
          <TextField fullWidth={true} label="Notes" inputRef={notesRef} {...notesRest} />
        </div>
        <div>
          <TextField fullWidth={true} label="Photo" inputRef={photoRef} {...photoRest} type="file" />
        </div>
        {/* <FormLabel>Photos</FormLabel> */}
        {/* <DropzoneArea
          onChange={handleFileDrop}
          acceptedFiles={['image/jpeg', 'image/png']}
          showPreviews={true}
          maxFileSize={1 * 1024 * 1024}
        /> */}
      </Box>
      <Typography variant="h5" color="textSecondary" sx={{ marginTop: 2 }}>
        Tags
      </Typography>
      <ControlledAutoComplete
        control={control}
        multiple={true}
        options={tagsData.tags}
        getOptionLabel={(t) => t.name}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              marginLeft: 2,
            }}
            label="Tags"
            placeholder="Tags"
          />
        )}
        onChange={async (_event, newValues, callback) => {
          if (newValues) {
            const validatedTags = isArrayType(newValues)
              ? await setTags(newValues.map((v) => (typeof v === 'string' ? v : v.name)))
              : await setTags([typeof newValues === 'string' ? newValues : newValues.name]);
            if (validatedTags.data?.findOrCreateTags) {
              return callback(validatedTags.data.findOrCreateTags as Tag[]);
            }
          } else {
            return callback(newValues);
          }
        }}
        name="tags"
        defaultValue={[]}
      />
      <Typography variant="h5" color="textSecondary" sx={{ marginTop: 2 }}>
        Sections
      </Typography>
      <EditSections control={control} register={register} recipeId={recipeId} />
      <LowerRightFab color="primary" type="submit" aria-label="save">
        <SaveIcon />
      </LowerRightFab>
    </form>
  );
};

export default EditRecipe;
