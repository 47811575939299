/* eslint-disable react/prop-types */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import { RecipeQuery } from '../generated/graphql';

interface Props {
  steps: NonNullable<RecipeQuery['recipe']>['sections'][0]['steps'];
}

export const RecipeSteps: React.FunctionComponent<Props> = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        // maxWidth: 360,
        backgroundColor: 'palette.background.paper',
      }}
    >
      {props.steps.map((step, index) => (
        <Typography key={index} paragraph={true}>
          {index + 1}. {step.description}
        </Typography>
      ))}
    </Box>
  );
};

export default RecipeSteps;
