export const EQUIVALENTS: {
  [key: string]: {
    gt?: {
      scaleAt: number;
      multiplier: number;
      unit: string;
    };
    lt?: {
      scaleAt: number;
      multiplier: number;
      unit: string;
    };
  };
} = {
  tsp: {
    gt: {
      scaleAt: 3,
      multiplier: 1 / 3,
      unit: 'tbsp',
    },
  },
  tbsp: {
    gt: {
      scaleAt: 4,
      multiplier: 1 / 16 + Number.EPSILON,
      unit: 'cup',
    },
    lt: {
      scaleAt: 1,
      multiplier: 3,
      unit: 'tsp',
    },
  },
  cup: {
    lt: {
      scaleAt: 0.25,
      multiplier: 16,
      unit: 'tbsp',
    },
  },
  // g: {
  //   gt: {
  //     scaleAt: 1000,
  //     multiplier: 1 / 1000,
  //     unit: 'kg',
  //   },
  // },
  // kg: {
  //   lt: {
  //     scaleAt: 1 - Number.EPSILON,
  //     multiplier: 1000,
  //     unit: 'g',
  //   },
  // },
};

const roundto4DP = (num: number): number => Math.round(num * 10000 + Number.EPSILON) / 10000;

export const normalizeAmount = (
  amount: number,
  unitName?: string,
): {
  amount: number;
  unitName?: string;
  extra?: {
    remainder: number;
    amount: number;
    unitName: string;
  };
} => {
  if (!unitName || !EQUIVALENTS.hasOwnProperty(unitName)) {
    return {
      amount,
      unitName,
    };
  }

  const lookupItem = EQUIVALENTS[unitName];

  if (lookupItem.gt && amount >= lookupItem.gt.scaleAt) {
    return normalizeAmount(amount * lookupItem.gt.multiplier, lookupItem.gt.unit);
  } else if (lookupItem.lt && amount < lookupItem.lt.scaleAt) {
    return normalizeAmount(amount * lookupItem.lt.multiplier, lookupItem.lt.unit);
  } else {
    const remainder3 = amount % (1 / 3);
    const remainder4 = amount % (1 / 4);
    let extra;

    if (remainder3 !== 0 && remainder4 !== 0 && lookupItem.lt) {
      const useRemainder =
        unitName === 'cup'
          ? (lookupItem.lt.multiplier * remainder3) % (1 / 4) === 0
            ? remainder3
            : remainder4
          : remainder4;

      extra = {
        remainder: roundto4DP(useRemainder),
        amount: roundto4DP(lookupItem.lt.multiplier * useRemainder),
        unitName: lookupItem.lt.unit,
      };
    }

    return {
      amount: roundto4DP(amount),
      unitName,
      extra,
    };
  }
};
