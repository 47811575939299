import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Sentry from '@sentry/browser';
import React, { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import AllTags from './components/AllTags';
import EditRecipe from './components/edit/EditRecipe';
import Frame from './components/Frame';
import Home from './components/Home';
import NavBar from './components/NavBar';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import Recipe from './components/Recipe';
import TaggedRecipes from './components/TaggedRecipes';
import { BreadCrumbProvider } from './context/BreadCrumbContext';
import { useAuth0 } from './react-auth0-spa';
import { darkTheme, lightTheme } from './utils/theme';

function App(): JSX.Element {
  const { isInitializing, isAuthenticated, user } = useAuth0();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  if (isInitializing) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email,
        id: user.sub,
        username: user.email,
      });
    });
  }

  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
          <Helmet>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
          <BrowserRouter>
            <header>
              <NavBar />
            </header>
            <BreadCrumbProvider>
              <Frame>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/recipe/:id/edit" element={<PrivateRoute />}>
                      <Route path="/recipe/:id/edit" element={<EditRecipe />} />
                    </Route>
                    <Route path="/recipe/:id" element={<Recipe />} />
                    <Route path="/tags" element={<AllTags />} />
                    <Route path="/tag/:name" element={<TaggedRecipes />} />
                    <Route path="/profile" element={<PrivateRoute />}>
                      <Route path="/profile" element={<Profile />} />
                    </Route>
                  </Routes>
                </Suspense>
              </Frame>
            </BreadCrumbProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
