import React, { Fragment } from 'react';
import { useAuth0 } from '../react-auth0-spa';

const Profile: React.FunctionComponent = () => {
  const { user } = useAuth0();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <code>{JSON.stringify(user, null, 2)}</code>
    </Fragment>
  );
};

export default Profile;
