/* eslint-disable react/prop-types */
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { RecipeQuery } from '../generated/graphql';
import IngredientList from './IngredientList';
import RecipeSteps from './RecipeSteps';

interface Props {
  section: NonNullable<RecipeQuery['recipe']>['sections'][0];
  hideSectionTitles?: boolean;
}

export const RecipeSection: React.FunctionComponent<Props> = (props) => {
  const [servings, setServings] = useState<string>(`${props.section.servings}`);

  return (
    <Box
      sx={{
        width: '100%',
        // maxWidth: 360,
        backgroundColor: 'palette.background.paper',
      }}
    >
      {!props.hideSectionTitles && <Typography variant="h5">{props.section.name}</Typography>}
      {props.section.prepTimeMinutes + props.section.cookTimeMinutes > 0 && (
        <Typography variant="body2" color="textSecondary" component="p">
          Plan {props.section.prepTimeMinutes} minutes to prepare and {props.section.cookTimeMinutes} minutes to cook (
          {props.section.prepTimeMinutes + props.section.cookTimeMinutes} minutes total)
        </Typography>
      )}
      {props.section.servings > 0 && (
        <>
          <Typography variant="body2" color="textSecondary" component="span">
            Makes&nbsp;
          </Typography>
          <TextField
            size="small"
            variant="standard"
            value={servings}
            name="servings"
            required={false}
            type="number"
            // inputProps={{ min: 1 }}
            style={{ width: '40px' }}
            onChange={(ev): void => (+ev.target.value > 0 ? setServings(ev.target.value) : setServings(''))}
          />{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.section.servingUnit}
          </Typography>
          {+servings !== props.section.servings && (
            <span>
              {' '}
              <Button variant="text" onClick={(): void => setServings(`${props.section.servings}`)}>
                reset
              </Button>
            </span>
          )}
        </>
      )}
      <Typography variant="h6">Ingredients</Typography>
      <IngredientList
        ingredients={props.section.ingredients}
        servingMultiplier={(+servings || 1) / props.section.servings}
      />

      <Typography variant="h6">Steps</Typography>
      <RecipeSteps steps={props.section.steps} />
    </Box>
  );
};

export default RecipeSection;
