import { Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BreadCrumbContext } from '../context/BreadCrumbContext';
import { useTagQuery } from '../generated/graphql';
import RecipePreviewCardGrid from './RecipePreviewCardGrid';

const TaggedRecipes: React.FunctionComponent = () => {
  const params = useParams();
  if (!params.name) {
    throw new Error(`Missing name parameter`);
  }

  const [{ fetching, error, data }] = useTagQuery({
    variables: {
      name: params.name,
    },
  });

  const [, setLevels] = useContext(BreadCrumbContext);

  useEffect(() => {
    const newLevels = [];

    if (data?.tag?.name) {
      newLevels.push({
        name: data?.tag?.name,
        location: `/recipe/${data?.tag?.name}`,
      });
    }

    setLevels(newLevels);

    return (): void => setLevels([]);
  }, [data, setLevels]);

  if (fetching) return <p>Loading...</p>;
  if (error || !data) return <p>Error :( {JSON.stringify(error)}</p>;

  return (
    <>
      <Typography variant="h4" gutterBottom={true} color="textPrimary">
        Recipes tagged <b>{data?.tag?.name}</b>
      </Typography>
      <RecipePreviewCardGrid recipes={data?.tag?.recipes} />
    </>
  );
};

export default TaggedRecipes;
