/* eslint-disable react/prop-types */
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ENV from '../env';
import { RecipesQuery } from '../generated/graphql';

type Props = RecipesQuery['recipes'][0];

export const RecipePreviewCard: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
        }}
        onClick={(ev): void => {
          ev.preventDefault();
          navigate(`/recipe/${props.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              sx={{
                backgroundColor: 'red[500]',
              }}
            >
              {props.author?.name[0]}
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={props.title}
          subheader={props.tags?.map((tag) => (
            <Chip
              key={tag.name}
              sx={{
                marginRight: 1,
              }}
              label={tag.name}
              onClick={(ev): void => {
                ev.preventDefault();
                navigate(`/tag/${tag.name}`);
              }}
            />
          ))}
        />
        {props.photo?.length > 0 && (
          <CardMedia
            sx={{
              height: 0,
              paddingTop: '56.25%', // 16:9
            }}
            image={`${ENV.REACT_APP_BACKEND_URL}/file/${props.photo?.[0]?.id}`}
            title={props.title}
          />
        )}
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default RecipePreviewCard;
