import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
  OnDragEndResponder,
} from '@react-forked/dnd';
import React, { CSSProperties } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { RecipeFieldsFragment, useCreateOneStepMutation, useDeleteOneStepMutation } from '../../generated/graphql';

interface NestedFieldProps<TFieldValues> {
  sectionIndex: number;
  sectionId: string;
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
}

// type StepFormFields = RecipeFieldsFragment['sections'][0]['steps'][0];

export const EditSteps = ({
  sectionIndex,
  sectionId,
  control,
  register,
}: NestedFieldProps<RecipeFieldsFragment>): JSX.Element => {
  const [, createOneStep] = useCreateOneStepMutation();
  const [, deleteOneStep] = useDeleteOneStepMutation();

  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.steps` as 'sections.0.steps',
    keyName: 'fieldId',
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    move(result.source.index, result?.destination?.index || 0);
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ): CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver ? '1px solid white' : '',
    padding: 8,
    width: '100%',
  });

  const addStep = async (): Promise<void> => {
    const updateResult = await createOneStep(
      {
        description: '',
        recipeSection: {
          connect: {
            id: sectionId,
          },
        },
      },
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   createOneStep: {
      //     ...section!,
      //     steps: [
      //       ...section!.steps,
      //       {
      //         __typename: 'Step',
      //         id: '' + Math.random(),
      //         description: '',
      //         order,
      //       },
      //     ],
      //   },
      // },
    );

    if (updateResult.error) {
      alert(JSON.stringify(updateResult.error));
      return;
    }

    if (updateResult.data?.createOneStep) {
      append(updateResult.data.createOneStep);
    }
  };

  const removeStep = async (id: string, index: number): Promise<void> => {
    const deleteResult = await deleteOneStep({
      id,
    });

    if (deleteResult.error) {
      alert(JSON.stringify(deleteResult.error));
      return;
    }

    remove(index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {fields.map((item, stepIndex) => {
                const { ref: descRef, ...descRest } = register(
                  `sections.${sectionIndex}.steps.${stepIndex}.description` as 'sections.0.steps.0.description',
                  { required: true },
                );
                return (
                  <Draggable key={item.fieldId} draggableId={item.fieldId || ''} index={stepIndex}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <IconButton aria-label="remove" onClick={(): Promise<void> => removeStep(item.id, stepIndex)}>
                          <RemoveCircleOutlineIcon color="secondary" fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="reorder" {...provided.dragHandleProps}>
                          <DragHandleIcon color="primary" fontSize="small" />
                        </IconButton>
                        <input
                          type="hidden"
                          {...register(`sections.${sectionIndex}.steps.${stepIndex}.id` as 'sections.0.steps.0.id', {
                            required: true,
                          })}
                          defaultValue={item.id}
                        />
                        <input
                          type="hidden"
                          {...register(
                            `sections.${sectionIndex}.steps.${stepIndex}.order` as 'sections.0.steps.0.order',
                            { required: true },
                          )}
                          defaultValue={item.order}
                        />
                        <div style={{ width: '80%', display: 'inline-flex' }}>
                          <TextField
                            fullWidth={true}
                            multiline={true}
                            inputRef={descRef}
                            {...descRest}
                            label={`Description`}
                            required={true}
                            defaultValue={item.description}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button color="secondary" type="button" onClick={(): Promise<void> => addStep()}>
        Add a step
      </Button>
    </>
  );
};

export default EditSteps;
