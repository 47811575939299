/* eslint-disable react/prop-types */
import React, { useState } from 'react';

type ICrumbState = {
  name: string;
  location: string;
}[];

type ICrumbContext = [ICrumbState, (state: ICrumbState) => void];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const BreadCrumbContext = React.createContext<ICrumbContext>([[], (): void => {}]);

const BreadCrumbProvider: React.FunctionComponent = (props) => {
  const [crumbState, setCrumbState] = useState<ICrumbState>([]);

  return <BreadCrumbContext.Provider value={[crumbState, setCrumbState]}>{props.children}</BreadCrumbContext.Provider>;
};

export { BreadCrumbContext, BreadCrumbProvider };
