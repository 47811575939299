import { Typography } from '@mui/material';
import React from 'react';
import { useTagsQuery } from '../generated/graphql';
import { useAuth0 } from '../react-auth0-spa';
import RecipePreviewCardGrid from './RecipePreviewCardGrid';

const AllTags: React.FunctionComponent = () => {
  const { isAuthenticated } = useAuth0();
  const [{ fetching, error, data }] = useTagsQuery();

  if (fetching || isAuthenticated) return <p>Loading...</p>;
  if (error || !data) return <p>Error :( {JSON.stringify(error)}</p>;

  return (
    <>
      {data?.tags
        ?.filter((tag) => tag.recipes.length > 0)
        .map((tag) => (
          <div key={tag.id}>
            <Typography variant="h4" gutterBottom={true} color="textPrimary">
              Recipes tagged <b>{tag.name}</b>
            </Typography>
            <RecipePreviewCardGrid recipes={tag.recipes} />
          </div>
        ))}
    </>
  );
};

export default AllTags;
