import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
  OnDragEndResponder,
} from '@react-forked/dnd';
import pluralize from 'pluralize';
import React, { CSSProperties } from 'react';
import { Control, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form';
import {
  Ingredient,
  IngredientUnitFieldsFragment,
  RecipeFieldsFragment,
  useCreateOneIngredientUnitMutation,
  useDeleteOneIngredientUnitMutation,
  useFindOrCreateIngredientsMutation,
  useIngredientsListQuery,
  useUnitSizesQuery,
} from '../../generated/graphql';
import ControlledAutoComplete from '../ControlledAutoComplete';
import ControlledSelect from '../ControlledSelect';

interface NestedFieldProps<TFieldValues> {
  sectionIndex: number;
  sectionId: string;
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
}

export const EditIngredients = ({
  sectionIndex,
  sectionId,
  control,
  register,
}: NestedFieldProps<RecipeFieldsFragment>): JSX.Element => {
  const [{ data: unitData }] = useUnitSizesQuery();
  const [{ data: ingredientsData }] = useIngredientsListQuery();

  const [, createOneIngredientUnit] = useCreateOneIngredientUnitMutation();
  const [, findOrCreateIngredient] = useFindOrCreateIngredientsMutation();
  const [, deleteOneIngredientUnit] = useDeleteOneIngredientUnitMutation();

  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.ingredients` as 'sections.0.ingredients',
    keyName: 'fieldId',
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    move(result.source.index, result?.destination?.index || 0);
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ): CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver ? '1px solid white' : '',
    padding: 8,
    width: '100%',
  });

  const addIngredient = async (): Promise<void> => {
    const updateResult = await createOneIngredientUnit(
      {
        notes: '',
        amount: 1,
        recipeSectionId: sectionId,
      },
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   createOneStep: {
      //     ...section!,
      //     steps: [
      //       ...section!.steps,
      //       {
      //         __typename: 'Step',
      //         id: '' + Math.random(),
      //         description: '',
      //         order,
      //       },
      //     ],
      //   },
      // },
    );

    if (updateResult.error) {
      alert(JSON.stringify(updateResult.error));
      return;
    }

    if (updateResult.data?.createOneIngredientUnit) {
      append(updateResult.data.createOneIngredientUnit);
    }
  };

  const removeIngredient = async (id: string, index: number): Promise<void> => {
    const deleteResult = await deleteOneIngredientUnit({
      id,
    });

    if (deleteResult.error) {
      alert(JSON.stringify(deleteResult.error));
      return;
    }

    remove(index);
  };

  const setIngredientName = async (value?: string | null) => {
    if (!value) {
      return;
      // no value specified yet
    }

    let valueName = '';
    if (typeof value === 'string') {
      valueName = value;
    }

    return findOrCreateIngredient({
      names: [pluralize.singular(valueName)],
    });
  };

  if (!unitData?.unitSizes || !ingredientsData?.ingredients) {
    console.log(`Was waiting for load`);
    return <div>Loading...</div>;
  }

  const ingredients = useWatch({
    control,
    name: `sections.${sectionIndex}.ingredients` as 'sections.0.ingredients',
  }) as Array<{ __typename?: 'IngredientUnit' } & IngredientUnitFieldsFragment>;

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {fields.map((item, ingredientIndex) => {
                const { ref: notesRef, ...notesRest } = register(
                  `sections.${sectionIndex}.ingredients.${ingredientIndex}.notes` as 'sections.0.ingredients.0.notes',
                );
                const { ref: amountRef, ...amountRest } = register(
                  `sections.${sectionIndex}.ingredients.${ingredientIndex}.amount` as 'sections.0.ingredients.0.amount',
                  {
                    valueAsNumber: true,
                  },
                );

                return (
                  <Draggable key={item.fieldId} draggableId={item.fieldId || ''} index={ingredientIndex}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <IconButton
                          aria-label="remove"
                          onClick={(): Promise<void> => removeIngredient(item.id, ingredientIndex)}
                        >
                          <RemoveCircleOutlineIcon color="secondary" fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="reorder" {...provided.dragHandleProps}>
                          <DragHandleIcon color="primary" fontSize="small" />
                        </IconButton>
                        <input
                          type="hidden"
                          {...register(
                            `sections.${sectionIndex}.ingredients.${ingredientIndex}.id` as 'sections.0.ingredients.0.id',
                            {
                              required: true,
                            },
                          )}
                          defaultValue={item.id}
                        />
                        <input
                          type="hidden"
                          {...register(
                            `sections.${sectionIndex}.ingredients.${ingredientIndex}.order` as 'sections.0.ingredients.0.order',
                            {
                              required: true,
                            },
                          )}
                          required={true}
                          defaultValue={item.order}
                        />
                        <TextField
                          label="Amount"
                          {...amountRest}
                          inputRef={amountRef}
                          type="number"
                          style={{ maxWidth: 100 }}
                          defaultValue={item.amount}
                        />
                        <ControlledSelect
                          label="Unit"
                          name={
                            `sections.${sectionIndex}.ingredients.${ingredientIndex}.unit.id` as 'sections.0.ingredients.0.unit.id'
                          }
                          required={true}
                          defaultValue={item.unit?.id || ''}
                          control={control}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {unitData.unitSizes.map((unit) => (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            <MenuItem value={unit.id} key={unit.id}>
                              {(ingredients?.[ingredientIndex]?.amount || 0) > 1 ? unit.plural : unit.name}
                            </MenuItem>
                          ))}
                        </ControlledSelect>
                        <ControlledAutoComplete
                          style={{ width: '300px', display: 'inline-flex' }}
                          control={control}
                          multiple={false}
                          options={ingredientsData.ingredients}
                          getOptionLabel={(t) => {
                            if (!t) {
                              return '';
                            }
                            return typeof t === 'string' ? t : t.name;
                          }}
                          getOptionSelected={(option, value) => {
                            if (!option || !value) {
                              return false;
                            }
                            return option.name === value.name;
                          }}
                          renderInput={(params) => <TextField {...params} label="Ingredient" />}
                          onChange={async (event, newValue, callback) => {
                            event.preventDefault();
                            if (newValue) {
                              const validatedName = await setIngredientName(
                                typeof newValue === 'string' ? newValue : (newValue as Ingredient).name,
                              );
                              if (validatedName?.data?.findOrCreateIngredients) {
                                return callback(validatedName.data.findOrCreateIngredients[0] as Ingredient);
                              }
                            } else {
                              return callback(newValue);
                            }
                          }}
                          name={
                            `sections.${sectionIndex}.ingredients.${ingredientIndex}.ingredient` as 'sections.0.ingredients.0.ingredient'
                          }
                          defaultValue={item.ingredient || null}
                        />
                        <TextField
                          label="Notes"
                          {...notesRest}
                          required={false}
                          inputRef={notesRef}
                          defaultValue={item.notes}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button color="secondary" type="button" onClick={(): Promise<void> => addIngredient()}>
        Add an ingredient
      </Button>
    </>
  );
};

export default EditIngredients;
