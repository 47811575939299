import { OptimisticMutationConfig } from '@urql/exchange-graphcache';
import { IngredientsListDocument, IngredientsListQuery, TagsListDocument, TagsListQuery } from '../generated/graphql';

export const optimistic: OptimisticMutationConfig = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  findOrCreateTags: (variables, cache, _info) => {
    const names = (variables.names || []) as string[];
    const cachedNames = cache.readQuery<TagsListQuery>({ query: TagsListDocument });
    const finalNames = names.map((name) => cachedNames?.tags.find((tag) => name === tag.name) || name);

    return finalNames.map((tag) => ({
      __typename: 'Tag',
      id: typeof tag === 'string' ? `id${tag}` : tag.id,
      name: typeof tag === 'string' ? tag : tag.name,
    }));
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  findOrCreateIngredients: (variables, cache, _info) => {
    const names = (variables.names || []) as string[];
    const cachedNames = cache.readQuery<IngredientsListQuery>({ query: IngredientsListDocument });
    const finalNames = names.map(
      (name) => cachedNames?.ingredients.find((ingredient) => name === ingredient.name) || name,
    );

    return finalNames.map((ingredient) => ({
      __typename: 'Ingredient',
      id: typeof ingredient === 'string' ? `id${ingredient}` : ingredient.id,
      name: typeof ingredient === 'string' ? ingredient : ingredient.name,
    }));
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createOneStep: (_variables, _cache, _info) => {
    /*
		id
    order
    description
    recipeSection {
      id
      steps {
        id
      }
    }
		*/

    return {
      __typename: 'Step',
      id: `tempStep${Math.random()}`,
      order: 99,
      description: '',
    };
  },
  // createOneRecipe: (variables, cache, info) => ({
  // 	__typename: 'Recipe',
  // 	id: variables.id,
  // 	favorite: true,
  // }),
  // updateOneRecipe: (variables, cache, info) => ({
  // 	__typename: 'Recipe',
  // 	id: variables.id,
  // 	favorite: true,
  // }),
};

export default optimistic;
