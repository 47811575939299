import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';

const PrivateRoute = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();

  const ele = isAuthenticated === true ? <Outlet /> : <Navigate to="/" />;

  return ele;
};

export default PrivateRoute;
